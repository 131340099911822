import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Typography, IconButton, FormControlLabel, Select, MenuItem, InputLabel, FormControl, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { Input, Button } from 'semantic-ui-react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomModal.css';
import { serverConnect } from "../Config/Config.js";

const CustomCheckbox = styled(Checkbox)({
  '&.MuiCheckbox-root': {
    color: '#1A1A1D',
  },
  '&.Mui-checked': {
    color: '#C3073F',
  },
});

const CustomSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#C3073F"
    },
    "&:hover fieldset": {
      borderColor: "#C3073F"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#C3073F"
    },
    "& .MuiSelect-select": {
      color: 'white',
      fontSize: '14px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    "& .MuiSvgIcon-root": {
      color: 'white',
    },
  }
});

const CustomModal = ({ isOpen, onClose, nickname, mapCoordinates }) => {
  const [formData, setFormData] = useState({ width: '', height: '', message: '', dangerLevel: '', category: '' });
  const [errors, setErrors] = useState({});
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const captchaRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      clearForm();
      if (mapCoordinates.latitude && mapCoordinates.longitude) {
        setFormData(prevData => ({
          ...prevData,
          width: mapCoordinates.latitude,
          height: mapCoordinates.longitude
        }));
      }
    }
  }, [isOpen, mapCoordinates]);

  useEffect(() => {
    validateForm();
  }, [formData, captchaToken, isAnonymous]);

  const clearForm = () => {
    setFormData({ width: '', height: '', message: '', dangerLevel: '', category: '' });
    setErrors({});
    setCaptchaToken(null);
    setIsFormValid(false);
    setIsAnonymous(false);
  };

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'width':
        if (!value || isNaN(value)) {
          error = 'Введите действительную широту.';
        }
        break;
      case 'height':
        if (!value || isNaN(value)) {
          error = 'Введите действительную долготу.';
        }
        break;
      case 'message':
        if (!value || value.length < 20) {
          error = 'Сообщение должно содержать не менее 20 символов.';
        }
        break;
      case 'category':
        if (!value) {
          error = 'Выберите категорию.';
        }
        break;
      case 'dangerLevel':
        if (!value) {
          error = 'Выберите уровень опасности.';
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateForm = () => {
    const isValid = 
      formData.width && !isNaN(formData.width) &&
      formData.height && !isNaN(formData.height) &&
      formData.message.length >= 20 &&
      formData.category &&
      formData.dangerLevel &&
      captchaToken;

    setIsFormValid(isValid);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token);
  };

  const handleCaptchaError = () => {
    if (captchaRef.current) {
      captchaRef.current.resetCaptcha();
    }
  };

  const handleCheckboxChange = (e) => {
    setIsAnonymous(e.target.checked);
  };

  const handleSubmit = async () => {
    if (isFormValid) {
      const date = new Date();
      const formattedDate = `${date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })} ${date.toLocaleDateString('ru-RU')}`;

      try {
        const response = await fetch(`${serverConnect.server}api/add_marker`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            latitude: formData.width,
            longitude: formData.height,
            message: formData.message,
            danger: formData.dangerLevel,
            createBy: isAnonymous ? 'анонимно' : nickname || 'анонимно',
            icon: formData.category,
            date: formattedDate,
            captchaToken: captchaToken
          })
        });

        const result = await response.json();
        if (response.ok) {
            setTimeout(() => {
              toast.success(result.message);
              clearForm();
              onClose();
            }, 10); 
        } else {
          toast.error(result.message);
          handleCaptchaError();
        }
      } catch (error) {
        toast.error("Ошибка при добавлении метки.");
        handleCaptchaError();
      }
    } else {
      toast.error("Пожалуйста, заполните все поля и пройдите капчу!");
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: '#2A2A2D', borderRadius: '10px', boxShadow: '0 0 15px 3px rgba(195, 7, 63, 0.5)', p: 4, color: 'white' }}>
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8, color: 'white' }}>
          <CloseIcon />
        </IconButton>
        <Typography id="modal-title" variant="h6" component="h2">Введите данные метки</Typography>
        <Box sx={{ mt: 2 }}>
        <div className="input-container">
          <label className="input-label">Широта</label>
          <Input
            placeholder="Введите широту..."
            className="custom-input"
            name="width"
            value={formData.width}
            onChange={handleInput}
            onBlur={handleBlur}
            fullWidth
            maxLength={15}  // Установка максимального количества символов
          />
          {errors.width && <span className="error-text">{errors.width}</span>}
        </div>
        <div className="input-container">
          <label className="input-label">Долгота</label>
          <Input
            placeholder="Введите долготу..."
            className="custom-input"
            name="height"
            value={formData.height}
            onChange={handleInput}
            onBlur={handleBlur}
            fullWidth
            maxLength={15}  // Установка максимального количества символов
          />
          {errors.height && <span className="error-text">{errors.height}</span>}
        </div>
        <div className="input-container">
          <label className="input-label">Сообщение</label>
          <Input
            placeholder="Введите сообщение..."
            className="custom-input"
            name="message"
            value={formData.message}
            onChange={handleInput}
            onBlur={handleBlur}
            fullWidth
            maxLength={1000}  // Установка максимального количества символов
          />
          {errors.message && <span className="error-text">{errors.message}</span>}
        </div>

          <div className="input-container" style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormControlLabel
              control={<CustomCheckbox checked={isAnonymous} onChange={handleCheckboxChange} />}
              label={<label style={{ color: 'white', fontSize: '16px' }}>Анонимно?</label>}
            />
            <div style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
              <FormControl variant="outlined" sx={{ minWidth: 80, marginRight: 2, maxWidth: 120 }}>
                <InputLabel id="category-label" style={{ color: 'white' }}>Категория</InputLabel>
                <CustomSelect
                  labelId="category-label"
                  id="category-select"
                  name="category"
                  value={formData.category}
                  onChange={handleInput}
                  label="Категория"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: '#2A2A2D',
                        color: 'white',
                      },
                    },
                  }}
                >
                  {['Полицейский', 'Полицейская машина'].map(category => (
                    <MenuItem key={category} value={category}>{category}</MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
              <FormControl variant="outlined" sx={{ minWidth: 80 }}>
                <InputLabel id="danger-level-label" style={{ color: 'white' }}>Уровень опасности</InputLabel>
                <CustomSelect
                  labelId="danger-level-label"
                  id="danger-level-select"
                  name="dangerLevel"
                  value={formData.dangerLevel}
                  onChange={handleInput}
                  label="Уровень опасности"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: '#2A2A2D',
                        color: 'white',
                      },
                    },
                  }}
                >
                  {[1, 2, 3, 4, 5].map(level => (
                    <MenuItem key={level} value={level}>{level}</MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </div>
          </div>
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <HCaptcha
              sitekey="dd123fb4-e0b8-4636-a408-3734ed7ae054"
              onVerify={handleCaptchaVerify}
              ref={captchaRef}
            />
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button
              className="gradient-button"
              disabled={!isFormValid}
              onClick={handleSubmit}
            >
              Создать
            </Button>
          </div>
        </Box>
        <ToastContainer />
      </Box>
    </Modal>
  );
};

export default CustomModal;
