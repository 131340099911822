import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { ButtonContent, Button, Icon, Dropdown } from 'semantic-ui-react';
import { RiLoginCircleFill, RiArrowDropDownLine, RiArrowDropRightLine } from "react-icons/ri";
import { FaMapLocationDot } from "react-icons/fa6";
import { GrCatalog } from "react-icons/gr";
import { HiOutlineMenu } from "react-icons/hi";
import { BiInfoCircle } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import '../Main/Main.css';
import './NavBarComponent.css';
import PreviewImage from '../Assets/logo.svg'; // Импортируем изображение

const NavBarComponent = ({ nickname, isUserLoading, toggleMenu, menuOpen, iconState, handleKebabClick, handleMapClick, handleLoginClick, handleRegistrationClick }) => {
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const truncateNickname = (nickname) => {
    if (!nickname) return '';
    return nickname.length > 6 ? nickname.substring(0, 6) + '...' : nickname;
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    window.location.reload();
  };

  const dropdownOptions = [
    { key: 'logout', text: 'Выйти', icon: 'sign out', onClick: handleLogout },
  ];

  const handleMainMenuToggle = () => {
    setIsUserDropdownOpen(false);
    toggleMenu();
  };

  const handleUserDropdownToggle = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
    if (!isUserDropdownOpen && menuOpen) {
      toggleMenu();
    }
  };

  return (
    <>
      <Navbar className="custom-navbar" expand="lg">
        <Navbar.Brand href="/">
          <img src={PreviewImage} alt="Preview" style={{ width: '55px', height: '55px' }} /> {/* Используем изображение */}
          <span className="navbar-text">RMaps</span>
        </Navbar.Brand>
        <div className="center-nav">
          {!isUserLoading && (
            <Nav className="nav-links">
              <Nav.Link onClick={() => navigate('/')} className="navbar-link">
                <GrCatalog style={{ fontSize: '28px' }} />
              </Nav.Link>
              <Nav.Link onClick={handleMapClick} className="navbar-link">
                <FaMapLocationDot style={{ fontSize: '28px' }} />
              </Nav.Link>
              <Nav.Link href="#footer" className="navbar-link">
                <BiInfoCircle style={{ fontSize: '28px' }} />
              </Nav.Link>
            </Nav>
          )}
        </div>
        <Nav className="ml-auto nav-buttons">
          {isUserLoading ? (
            <div style={{ fontSize: '24px', color: 'white' }}>Loading...</div>
          ) : (
            <>
              {nickname ? (
                <Dropdown
                  trigger={
                    <span style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color: 'white' }} onClick={handleUserDropdownToggle}>
                      <span style={{ fontSize: '24px', marginLeft: '100px'}}>{truncateNickname(nickname)}</span>
                      <RiArrowDropDownLine size={24} />
                    </span>
                  }
                  options={dropdownOptions}
                  pointing="top right"
                  icon={null}
                  className="custom-dropdown-menu"
                  open={isUserDropdownOpen}
                />
              ) : (
                <>
                  <Button animated className="gradient-button" onClick={handleLoginClick}>
                    <ButtonContent visible>Войти</ButtonContent>
                    <ButtonContent hidden>
                      <Icon name='arrow right' />
                    </ButtonContent>
                  </Button>
                  <Button animated='vertical' className="gradient-button" style={{ marginLeft: '10px' }} onClick={handleRegistrationClick}>
                    <ButtonContent visible>Регистрация</ButtonContent>
                    <ButtonContent hidden>
                      <div className='button-hover'>
                        <RiLoginCircleFill /><span style={{ marginLeft: '10px' }}>Начать</span>
                      </div>
                    </ButtonContent>
                  </Button>
                </>
              )}
            </>
          )}
        </Nav>
        <div className='menu-container'>
          {isUserLoading ? (
            <div className="nickname-container" style={{ fontSize: '20px', color: 'white' }}>
              Loading...
            </div>
          ) : (
            nickname && (
              <div className="nickname-container" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color: 'white' }}>
                <Dropdown
                  trigger={
                    <span style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color: 'white' }} onClick={handleUserDropdownToggle}>
                      <span style={{ fontSize: '20px' }}>{truncateNickname(nickname)}</span>
                      <RiArrowDropDownLine size={24} style={{ marginRight: '10px' }} />
                    </span>
                  }
                  options={dropdownOptions}
                  pointing="top right"
                  icon={null}
                  className="custom-dropdown-menu"
                  open={isUserDropdownOpen}
                />
              </div>
            )
          )}
          <div className={`menu-icon ${iconState}`} onClick={handleMainMenuToggle}>
            <HiOutlineMenu size={30} color="white" />
          </div>
        </div>
      </Navbar>
      <div className={`dropdown-menu custom-dropdown-menu ${menuOpen ? 'open' : ''}`}>
        {!isUserLoading && (
          <>
            <Nav.Link onClick={() => navigate('/')} className="navbar-link" style={{ fontSize: '18px' }}>
              <RiArrowDropRightLine style={{ fontSize: '22px' }} />
              <span style={{ marginLeft: '3px' }}>Каталог</span>
            </Nav.Link>
            <Nav.Link onClick={handleMapClick} className="navbar-link" style={{ fontSize: '18px' }}>
              <RiArrowDropRightLine style={{ fontSize: '22px' }} />
              <span style={{ marginLeft: '3px' }}>Карта</span>
            </Nav.Link>
            <Nav.Link href="#footer" className="navbar-link" style={{ fontSize: '18px' }}>
              <RiArrowDropRightLine style={{ fontSize: '22px' }} />
              <span style={{ marginLeft: '3px' }}>Контакты</span>
            </Nav.Link>
          </>
        )}
        <div className="button-container">
          {isUserLoading ? (
            <div className="nickname-container" style={{ fontSize: '20px', color: 'white' }}>
              Loading...
            </div>
          ) : (
            nickname ? (
              <div className="nickname-container">
                <span></span>
              </div>
            ) : (
              <>
                <Button animated className="gradient-button" style={{ marginRight: '10px' }} onClick={handleLoginClick}>
                  <ButtonContent visible>Войти</ButtonContent>
                  <ButtonContent hidden>
                    <Icon name='arrow right' />
                  </ButtonContent>
                </Button>
                <Button animated='vertical' className="gradient-button" onClick={handleRegistrationClick}>
                  <ButtonContent visible>Регистрация</ButtonContent>
                  <ButtonContent hidden>
                    <div className='button-hover'>
                      <RiLoginCircleFill /><span style={{ marginLeft: '10px' }}>Начать</span>
                    </div>
                  </ButtonContent>
                </Button>
              </>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default NavBarComponent;
