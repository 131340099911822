import React, { useState, useEffect } from 'react';
import './Main.css';
import { Tooltip } from 'react-tooltip';
import { Button, Icon, Input } from 'semantic-ui-react'; // Импорт Button, Icon, и Input
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { decryptData } from '../Crypt/Crypt.js';
import { serverConnect } from '../Config/Config.js';
import MapComponent from '../Components/MapComponent.js';
import CustomModal from '../Components/CustomModal.js';
import NavBarComponent from '../Components/NavbarComponent.js';
import { toast, ToastContainer } from 'react-toastify';
import FooterComponent from '../Components/FooterComponent.js';

const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2FkcTJ3ZCIsImEiOiJjbTBsdmwzMXQwOGExMmtxemYybXMwb2M0In0.XS5fDlfABEQhvmF486mmKA';

const MapPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [cryptoData, setCryptoData] = useState([]);
  const [btcPrice, setBtcPrice] = useState(null);
  const [iconState, setIconState] = useState('close');
  const [nickname, setNickname] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [address, setAddress] = useState('');
  const [mapCoordinates, setMapCoordinates] = useState({ latitude: '', longitude: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBanned, setIsBanned] = useState(false); // Новое состояние для проверки, забанен ли пользователь
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setIconState(menuOpen ? 'close' : 'open');
  };

  const handleKebabClick = () => {
    alert('burger menu');
  };

  const handleMapClick = () => {
    navigate('/map');
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000 && menuOpen) {
        setMenuOpen(false);
        setIconState('close');
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuOpen]);

  useEffect(() => {
    const checkAuthToken = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const decryptedToken = decryptData(token);
          const response = await axios.post(`${serverConnect.server}api/user`, { token: decryptedToken });
          if (response.data.nickname) {
            setNickname(response.data.nickname);
          }
          if (response.data.isBanned === "true") { // Проверяем статус бана как строку
            setIsBanned(true);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken');
          } else {
            console.error('Error fetching user data', error);
          }
        }
      }
      setIsUserLoading(false);
    };

    checkAuthToken();
  }, []);

  const handleRegistrationClick = () => {
    navigate('/registration');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const truncateNickname = (nickname) => {
    if (!nickname) return '';
    return nickname.length > 6 ? nickname.substring(0, 6) + '...' : nickname;
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleAddressSearch = async () => {
    if (address.trim() === '') return;

    try {
      const response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${MAPBOX_TOKEN}&language=ru`);
      if (response.data && response.data.features && response.data.features.length > 0) {
        const { center } = response.data.features[0];
        setMapCoordinates({ longitude: center[0], latitude: center[1], zoom: 15 });
      } else {
        toast.info('Адрес не найден');
        setMapCoordinates({ longitude: '', latitude: '' });
      }
    } catch (error) {
      console.error('Ошибка при поиске адреса', error);
      toast.error('Ошибка при поиске адреса');
      setMapCoordinates({ longitude: '', latitude: '' });
    }
  };

  const handleMarkerClick = async () => {
    if (address.trim() === '') {
      // Если адрес не введен, просто открываем модальное окно
      setIsModalOpen(true);
      return;
    }
  
    try {
      const response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${MAPBOX_TOKEN}&language=ru`);
      if (response.data && response.data.features && response.data.features.length > 0) {
        const { center } = response.data.features[0];
        setMapCoordinates({ longitude: center[0], latitude: center[1], zoom: 15 });
      } else {
        toast.info('Адрес не найден');
        setMapCoordinates({ longitude: '', latitude: '' });
      }
    } catch (error) {
      console.error('Ошибка при поиске адреса', error);
      toast.error('Ошибка при поиске адреса');
      setMapCoordinates({ longitude: '', latitude: '' });
    } finally {
      setIsModalOpen(true);
    }
  };
  

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {isFirstLoad && isLoading ? (
        <div className="loading-screen">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <NavBarComponent
            nickname={nickname}
            isUserLoading={isUserLoading}
            toggleMenu={toggleMenu}
            menuOpen={menuOpen}
            iconState={iconState}
            handleKebabClick={handleKebabClick}
            handleMapClick={handleMapClick}
            handleLoginClick={handleLoginClick}
            handleRegistrationClick={handleRegistrationClick}
          />
          <div className="main map-container-page">
            <div className="map-wrapper">
              <MapComponent mapCoordinates={mapCoordinates} isBanned={isBanned} />
            </div>
            {/* <div className="controls">
              <Input
                icon={{ name: 'search', link: true, onClick: handleAddressSearch }}
                placeholder="Введите адрес..."
                className="custom-input"
                style={{ height: '35px' }}
                iconPosition='right'
                value={address}
                onChange={handleAddressChange}
                onKeyDown={(e) => e.key === 'Enter' && handleAddressSearch()}
              />
              <Button
                animated
                className="gradient-button"
                disabled={!nickname || isBanned} // Блокировка кнопки, если пользователь забанен
                data-tip={isBanned ? "Вам запрещено создавать метки" : !nickname ? "Пожалуйста, авторизуйтесь" : ""}
                onClick={handleMarkerClick}
              >
                <Button.Content visible>Создать метку</Button.Content>
                <Button.Content hidden>
                  <Icon name='plus' />
                </Button.Content>
              </Button>
              <Tooltip place="top" type="dark" effect="float" />
            </div> */}
          </div>
          {/* <CustomModal isOpen={isModalOpen} onClose={closeModal} nickname={nickname} mapCoordinates={mapCoordinates} />
          <div style={{marginTop: '-30px'}}>
            <FooterComponent />
          </div> */}
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default MapPage;
