import React, { useEffect, useState, useRef } from 'react';
import { MessageBox } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { Button, Icon, Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import './ChatComponent.css';
import axios from 'axios';
import { serverConnect } from '../Config/Config.js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import moment from 'moment-timezone';
import 'moment/locale/ru'; // Импорт русского локаля
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { decryptData } from '../Crypt/Crypt.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextareaAutosize from 'react-textarea-autosize';

moment.locale('ru');

const ChatComponent = ({ userNickname, userId }) => {
  const [messages, setMessages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [newMessage, setNewMessage] = useState('');
  const [contextMenu, setContextMenu] = useState(null);
  const [chatStatus, setChatStatus] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isBanned, setIsBanned] = useState(false);
  const [replyTo, setReplyTo] = useState(null);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingText, setEditingText] = useState('');
  const containerRef = useRef(null);
  const scrollButtonRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const ws = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false); // Новое состояние для управления прокруткой

  // Функция для обработки сообщения (разбор даты, форматирование ответа и т.д.)
  const processMessage = (message) => {
    const parsedDate = parseDate(message.date);
    console.log('Processing message:', {
      ...message,
      date: parsedDate,
    });
    return {
      ...message,
      is_changed: message.is_changed === 'true',
      date: parsedDate,
      time: formatTime(parsedDate),
      reply_to: message.reply_to
        ? {
            who_send: truncateString(message.reply_to_who_send || 'Unknown', 10),
            message: truncateString(message.reply_to_message || '...', 15),
          }
        : null,
    };
  };

  // Функция для сохранения отредактированного сообщения
  const handleSaveEdit = async () => {
    if (!editingMessageId) return;

    const trimmedText = editingText.trim();
    if (trimmedText.length < 2 || trimmedText.length > 1000) {
      toast.error("Длина сообщения должна быть от 2 до 1000 символов");
      return;
    }

    try {
      const token = localStorage.getItem('authToken');
      const decryptedToken = decryptData(token);
      const response = await axios.post(`${serverConnect.server}/api/chat/edit`, {
        message_id: editingMessageId,
        new_message: trimmedText,
        token: decryptedToken,
      });

      console.log('Ответ от API при редактировании:', response.data); // Для отладки

      if (response.data.status === 'success') {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.message_id === editingMessageId
              ? { ...msg, message: trimmedText, is_changed: true }
              : msg
          )
        );
        setEditingMessageId(null);
        setEditingText('');
        toast.success(response.data.message || "Сообщение успешно отредактировано");
      } else {
        toast.error(response.data.message || "Ошибка при редактировании сообщения");
      }
    } catch (error) {
      console.error('Ошибка при редактировании сообщения:', error);
      toast.error("Ошибка редактирования сообщения");
    }
  };

  // Функция для отмены редактирования
  const handleCancelEdit = () => {
    setEditingMessageId(null);
    setEditingText('');
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const decryptedToken = decryptData(token);
        const response = await axios.post(`${serverConnect.server}/api/user`, {
          token: decryptedToken,
        });
        setIsAdmin(response.data.isAdmin === "true");
        setIsBanned(response.data.isBanned === "true");
      } catch (error) {
        console.error('Ошибка при получении информации о пользователе:', error);
      }
    };

    fetchUserInfo();
    const intervalId = setInterval(fetchUserInfo, 30000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchChatStatus = async () => {
      try {
        const response = await axios.get(`${serverConnect.server}/api/status`);
        setChatStatus(response.data.chat);
      } catch (error) {
        console.error('Ошибка при получении статуса чата:', error);
        setChatStatus(1);
      }
    };

    fetchChatStatus();
    const intervalId = setInterval(fetchChatStatus, 15000);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleScroll = () => {
      setContextMenu(null);
    };

    const handleClick = (e) => {
      if (
        showEmojiPicker &&
        !e.target.closest('.emoji-picker') &&
        !e.target.closest('.custom-inputs')
      ) {
        setShowEmojiPicker(false);
      }
      setContextMenu(null);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('click', handleClick);
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
      containerRef.current.addEventListener('click', handleClick);
    }

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('click', handleClick);
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
        containerRef.current.removeEventListener('click', handleClick);
      }
    };
  }, [showEmojiPicker]);

  useEffect(() => {
    if (chatStatus === 0) {
      loadMessages(0, true).then(() => {
        if (containerRef.current) {
          setTimeout(() => {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
          }, 0);
        }
      });
    }
  }, [chatStatus]);

  useEffect(() => {
    if (chatStatus === 0) {
      // Подключение к WebSocket
      const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      const wsUrl = `${protocol}://${serverConnect.serverws}/ws/chat`; // Убедитесь в правильности порта
      ws.current = new WebSocket(wsUrl);

      ws.current.onopen = () => {
        console.log('WebSocket подключен');
      };

      ws.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleWebSocketMessage(data);
      };

      ws.current.onclose = () => {
        console.log('WebSocket отключен');
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket ошибка:', error);
      };

      return () => {
        if (ws.current) {
          ws.current.close();
        }
      };
    }
  }, [chatStatus]);

  const sortMessages = (a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    
    if (dateA > dateB) return 1;
    if (dateA < dateB) return -1;
    
    // Если даты равны, сортируем по message_id
    if (a.message_id > b.message_id) return 1;
    if (a.message_id < b.message_id) return -1;
    
    return 0;
  };

  const handleWebSocketMessage = (data) => {
    const { action, message, message_id, new_message, user_id } = data;
  
    switch (action) {
      case 'new_message':
        const processedMessage = processMessage(message);
        console.log('New WebSocket message:', processedMessage);
  
        setMessages((prevMessages) =>
          [...prevMessages, processedMessage].sort(sortMessages)
        );
  
        if (isAtBottom()) {
          setShouldScroll(true);
        }
        break;
      case 'edit':
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.message_id === message_id
              ? { ...msg, message: new_message, is_changed: true }
              : msg
          )
        );
        break;
      case 'delete':
        setMessages((prevMessages) =>
          prevMessages.filter((msg) => msg.message_id !== message_id)
        );
        break;
      case 'ban':
        if (userId === user_id) {
          setIsBanned(true);
          toast.error("Вы были забанены.");
        }
        break;
      default:
        break;
    }
  };

  const isAtBottom = () => {
    if (!containerRef.current) return false;
    return (
      containerRef.current.scrollHeight -
        containerRef.current.scrollTop <=
      containerRef.current.clientHeight + 1
    );
  };

  const formatCustomDate = (date) => {
    return moment(date).tz('Europe/Moscow').format('HH:mm:ss:SSS DD-MM-YYYY');
  };

  const parseDate = (dateString) => {
    return moment
      .tz(dateString, 'HH:mm:ss:SSS DD-MM-YYYY', 'Europe/Moscow')
      .toDate();
  };

  const formatTime = (date) => {
    return moment(date).tz('Europe/Moscow').format('HH:mm');
  };

  const truncateString = (str, maxLength) => {
    if (typeof str !== 'string') return ''; // Обработка нестроковых значений
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + '...';
    }
    return str;
  };

  const loadMessages = async (newOffset, initial = false) => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await axios.get(`${serverConnect.server}/api/chat`, {
        params: { limit: 100, offset: newOffset },
      });
      const newMessages = response.data.map(processMessage);
      if (initial) {
        setMessages(newMessages.sort((a, b) => a.date - b.date));
      } else {
        const previousScrollHeight = containerRef.current
          ? containerRef.current.scrollHeight
          : 0;
        setMessages((prevMessages) =>
          [...newMessages, ...prevMessages].sort((a, b) => a.date - b.date)
        );
        setTimeout(() => {
          if (containerRef.current) {
            containerRef.current.scrollTop =
              containerRef.current.scrollHeight - previousScrollHeight;
          }
        }, 0);
      }
      setOffset(newOffset + 100);
      if (newMessages.length < 100) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Ошибка при загрузке сообщений:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleHideContextMenu = () => {
    setContextMenu(null);
  };

  useEffect(() => {
    window.addEventListener('wheel', handleHideContextMenu);

    return () => {
      window.removeEventListener('wheel', handleHideContextMenu);
    };
  }, []);

  const handleScrollEvent = () => {
    if (containerRef.current && containerRef.current.scrollTop === 0 && !loading && hasMore) {
      loadMessages(offset);
    }
    if (containerRef.current) {
      setShowScrollToBottom(
        containerRef.current.scrollTop <
          containerRef.current.scrollHeight - containerRef.current.clientHeight - 20
      );
    }
    setContextMenu(null); // Скрыть контекстное меню при прокрутке
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScrollEvent);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScrollEvent);
      }
    };
  }, [loading, hasMore, offset]);

  const formatDate = (date) => {
    return moment(date).tz('Europe/Moscow').format('DD MMMM YYYY');
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const sendMessage = async () => {
    if (isBanned) return;

    let formattedMessage = newMessage.trim();

    if (formattedMessage.length < 2 || formattedMessage.length > 1000) {
      toast.error("Длина сообщения должна быть от 2 до 1000 символов", { autoClose: 3000 });
      return;
    }

    const formattedDate = formatCustomDate(new Date());

    try {
      const token = localStorage.getItem('authToken');
      const decryptedToken = decryptData(token);
      const response = await axios.post(`${serverConnect.server}/api/chat/message`, {
        message: formattedMessage,
        id: userId,
        date: formattedDate,
        reply_to: replyTo ? replyTo.message_id : null,
        token: decryptedToken,
      });

      console.log('Ответ от API при отправке сообщения:', response.data); // Для отладки

      if (response.data.status === 'success') {
        setNewMessage('');
        setReplyTo(null);
        // Нет необходимости получать последние сообщения, WebSocket это сделает
        toast.success(response.data.message || "Сообщение отправлено успешно");
      } else {
        toast.error(response.data.message || "Ошибка отправки сообщения");
      }
    } catch (error) {
      console.error('Ошибка отправки сообщения:', error);
      toast.error("Ошибка отправки сообщения");
    }
  };

  const handleContextMenu = (e, messageId, isOwnMessage, source = 'message') => {
    e.preventDefault();
    let x, y, pointingDirection;

    if (source === 'button') {
      e.stopPropagation();
      const rect = e.currentTarget.getBoundingClientRect();
      x = rect.left;
      y = rect.top;
      if (isOwnMessage) {
        pointingDirection = 'top right';
      } else {
        pointingDirection = 'top left';
      }

      setContextMenu({
        x,
        y,
        messageId,
        isOwnMessage,
        isAdmin,
        pointing: pointingDirection,
      });
    } else {
      const { clientX, clientY } = e;
      pointingDirection = isOwnMessage ? 'right' : 'left';

      setContextMenu({
        x: clientX,
        y: clientY,
        messageId,
        isOwnMessage,
        isAdmin,
        pointing: pointingDirection,
      });
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Текст скопирован в буфер обмена");
    } catch (err) {
      console.error('Ошибка копирования в буфер обмена:', err);
      toast.error("Не удалось скопировать текст");
    }
  };

  const handleDropdownItemClick = async (action) => {
    switch (action) {
      case 'reply':
        const message = messages.find((m) => m.message_id === contextMenu.messageId);
        if (message) {
          setReplyTo({
            who_send: message.who_send,
            message: message.message,
            message_id: message.message_id,
          });
        }
        break;
      case 'copy':
        const messageCopy = messages.find((m) => m.message_id === contextMenu.messageId);
        if (messageCopy) {
          copyToClipboard(messageCopy.message);
        }
        break;
      case 'report':
        // Добавить функционал жалобы
        toast.info("Функция жалобы пока не реализована.");
        break;
      case 'edit':
        const messageToEdit = messages.find((m) => m.message_id === contextMenu.messageId);
        if (messageToEdit) {
          setEditingMessageId(messageToEdit.message_id);
          setEditingText(messageToEdit.message);
        }
        break;
      case 'delete':
        await deleteMessage(contextMenu.messageId);
        break;
      case 'ban':
        await banUser(contextMenu.messageId);
        break;
      default:
        break;
    }
    setContextMenu(null);
  };

  const deleteMessage = async (messageId) => {
    try {
      const token = localStorage.getItem('authToken');
      const decryptedToken = decryptData(token);
      const response = await axios.post(`${serverConnect.server}/api/chat/delete`, {
        message_id: messageId,
        token: decryptedToken,
      });

      if (response.data.success) {
        toast.success("Сообщение удалено");
        // Нет необходимости получать последние сообщения, WebSocket это сделает
      } else {
        toast.error(response.data.error || "Ошибка удаления сообщения");
      }
    } catch (error) {
      console.error('Ошибка при удалении сообщения:', error);
      toast.error("Ошибка удаления сообщения");
    }
  };

  const banUser = async (messageId) => {
    try {
      const token = localStorage.getItem('authToken');
      const decryptedToken = decryptData(token);
      const response = await axios.post(`${serverConnect.server}/api/chat/ban`, {
        message_id: messageId,
        token: decryptedToken,
      });

      if (response.data.success) {
        toast.success("Пользователь забанен");
        // Нет необходимости получать последние сообщения, WebSocket это сделает
      } else {
        toast.error(response.data.error || "Ошибка при попытке забанить пользователя");
      }
    } catch (error) {
      console.error('Ошибка при бане пользователя:', error);
      toast.error("Ошибка при попытке забанить пользователя");
    }
  };

  const handleEmojiSelect = (emoji) => {
    if (isBanned) return;
    setNewMessage((prevMessage) => prevMessage + emoji.native);
    setShowEmojiPicker(false);
  };

  const handleKeyPress = (event) => {
    if (isBanned) return;
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const renderUserNickname = (nickname) => {
    return truncateString(nickname, 10);
  };

  // useEffect для обработки прокрутки после обновления сообщений
  useEffect(() => {
    if (shouldScroll) {
      scrollToBottom();
      setShouldScroll(false);
    }
  }, [messages, shouldScroll]);

  if (chatStatus === null) {
    return <div>Загрузка...</div>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {chatStatus === 1 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <h1>Технические работы</h1>
        </div>
      ) : (
        <>
          <ToastContainer />
          <div
            ref={containerRef}
            onScroll={handleScrollEvent}
            onClick={() => setContextMenu(null)}
            style={{ flex: 1, overflowY: 'auto', paddingLeft: '25%', paddingRight: '25%' }}
            className={`chat-box ${chatStatus === 1 ? 'no-scroll' : ''}`}
          >
            {loading && <div className="loading-indicator">Загрузка...</div>}
            <TransitionGroup className="messages-container">
              {messages.map((message, index) => {
                const isFirst =
                  index === 0 || messages[index - 1].who_send !== message.who_send;
                const isNewDate =
                  index === 0 ||
                  new Date(messages[index - 1].date).toDateString() !==
                    new Date(message.date).toDateString();
                const isOwnMessage = message.who_send === userNickname;
                const hasEditedLabel = message.is_changed;

                return (
                  <CSSTransition key={message.message_id} timeout={500} classNames="message">
                    <React.Fragment>
                      {isNewDate && (
                        <div className="date-separator1">{formatDate(message.date)}</div>
                      )}
                      {editingMessageId === message.message_id ? (
                        <div
                          className={`message-wrapper ${
                            isOwnMessage ? 'own-message' : 'other-message'
                          }`}
                        >
                          <MessageBox
                            position={isOwnMessage ? 'right' : 'left'}
                            type="text"
                            text={
                              <div className="edit-message-content">
                                <TextareaAutosize
                                  value={editingText}
                                  onChange={(e) => setEditingText(e.target.value)}
                                  className="textarea-edit"
                                  minRows={3}
                                  maxRows={8}
                                />
                                <div className="edit-buttons">
                                  <Button
                                    icon
                                    className="edit-save-button"
                                    onClick={handleSaveEdit}
                                  >
                                    <Icon name="check" />
                                  </Button>
                                  <Button
                                    icon
                                    className="edit-cancel-button"
                                    onClick={handleCancelEdit}
                                  >
                                    <Icon name="times" />
                                  </Button>
                                </div>
                              </div>
                            }
                            title={renderUserNickname(message.who_send)}
                            date={message.date}
                            dateString={message.time}
                            className={`${
                              isOwnMessage ? 'right-message' : 'left-message'
                            } ${!isFirst ? 'no-notch' : ''} editing-message-box`}
                            reply={
                              message.reply_to
                                ? {
                                    title: message.reply_to.who_send,
                                    titleColor: '#c54141',
                                    message: truncateString(message.reply_to.message, 60),
                                    className: 'message-reply',
                                    titleClassName: 'message-reply-title',
                                    messageClassName: 'message-reply-text',
                                  }
                                : null
                            }
                            onReplyMessageClick={() => console.log('Ответить кликнуто!')}
                            onContextMenu={(e) =>
                              handleContextMenu(e, message.message_id, isOwnMessage)
                            }
                          />
                          <button
                            className={`message-options-button ${
                              isOwnMessage ? 'own-message-button' : 'other-message-button'
                            }`}
                            onClick={(e) => {
                              e.stopPropagation(); // Предотвращение всплытия события
                              handleContextMenu(e, message.message_id, isOwnMessage, 'button');
                            }}
                          >
                            <Icon name="pencil alternate" />
                          </button>
                        </div>
                      ) : (
                        <div
                          className={`message-wrapper ${
                            isOwnMessage ? 'own-message' : 'other-message'
                          }`}
                        >
                          <MessageBox
                            position={isOwnMessage ? 'right' : 'left'}
                            type="text"
                            text={
                              message.is_changed ? (
                                <div className="message-content">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: message.message.replace(/\n/g, '<br />'),
                                    }}
                                  />
                                  <div className="edited-label">редакт.</div>
                                </div>
                              ) : (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: message.message.replace(/\n/g, '<br />'),
                                  }}
                                />
                              )
                            }
                            title={renderUserNickname(message.who_send)}
                            date={message.date}
                            dateString={message.time}
                            className={`${
                              isOwnMessage ? 'right-message' : 'left-message'
                            } ${!isFirst ? 'no-notch' : ''}`}
                            reply={
                              message.reply_to
                                ? {
                                    title: message.reply_to.who_send,
                                    titleColor: '#c54141',
                                    message: truncateString(message.reply_to.message, 60),
                                    className: 'message-reply',
                                    titleClassName: 'message-reply-title',
                                    messageClassName: 'message-reply-text',
                                  }
                                : null
                            }
                            onReplyMessageClick={() => console.log('Ответить кликнуто!')}
                            onContextMenu={(e) =>
                              handleContextMenu(e, message.message_id, isOwnMessage)
                            }
                          />
                          {/* Кнопка редактирования */}
                          <button
                            className={`message-options-button ${
                              isOwnMessage ? 'own-message-button' : 'other-message-button'
                            }`}
                            onClick={(e) => {
                              e.stopPropagation(); // Предотвращение всплытия события
                              handleContextMenu(e, message.message_id, isOwnMessage, 'button');
                            }}
                          >
                            <Icon name="pencil alternate" />
                          </button>
                        </div>
                      )}
                    </React.Fragment>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </div>
          {contextMenu && (
            <div
              style={{
                position: 'fixed',
                top: `${contextMenu.y}px`,
                left: `${contextMenu.x}px`,
                zIndex: 1000,
              }}
              onClick={() => setContextMenu(null)}
            >
              <Dropdown open pointing={contextMenu.pointing} className="context-menu">
                <Dropdown.Menu>
                  {contextMenu.isOwnMessage ? (
                    <>
                      <Dropdown.Item
                        text="Редактировать"
                        onClick={() => handleDropdownItemClick('edit')}
                      >
                        <Icon name="edit" />
                        Редактировать
                      </Dropdown.Item>
                      <Dropdown.Item
                        text="Удалить"
                        onClick={() => handleDropdownItemClick('delete')}
                      >
                        <Icon name="trash" />
                        Удалить
                      </Dropdown.Item>
                      <Dropdown.Item
                        text="Скопировать"
                        onClick={() => handleDropdownItemClick('copy')}
                      >
                        <Icon name="copy" />
                        Скопировать
                      </Dropdown.Item>
                    </>
                  ) : isAdmin ? (
                    <>
                      <Dropdown.Item
                        text="Ответить"
                        onClick={() => handleDropdownItemClick('reply')}
                      >
                        <Icon name="reply" />
                        Ответить
                      </Dropdown.Item>
                      <Dropdown.Item
                        text="Скопировать"
                        onClick={() => handleDropdownItemClick('copy')}
                      >
                        <Icon name="copy" />
                        Скопировать
                      </Dropdown.Item>
                      <Dropdown.Item
                        text="Удалить"
                        onClick={() => handleDropdownItemClick('delete')}
                      >
                        <Icon name="trash" />
                        Удалить
                      </Dropdown.Item>
                      <Dropdown.Item
                        text="Забанить"
                        onClick={() => handleDropdownItemClick('ban')}
                      >
                        <Icon name="ban" />
                        Забанить
                      </Dropdown.Item>
                      <Dropdown.Item
                        text="Жалоба"
                        onClick={() => handleDropdownItemClick('report')}
                      >
                        <Icon name="flag" />
                        Жалоба
                      </Dropdown.Item>
                    </>
                  ) : (
                    <>
                      <Dropdown.Item
                        text="Ответить"
                        onClick={() => handleDropdownItemClick('reply')}
                      >
                        <Icon name="reply" />
                        Ответить
                      </Dropdown.Item>
                      <Dropdown.Item
                        text="Скопировать"
                        onClick={() => handleDropdownItemClick('copy')}
                      >
                        <Icon name="copy" />
                        Скопировать
                      </Dropdown.Item>
                      <Dropdown.Item
                        text="Жалоба"
                        onClick={() => handleDropdownItemClick('report')}
                      >
                        <Icon name="flag" />
                        Жалоба
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          {showScrollToBottom && (
            <Button
              ref={scrollButtonRef}
              icon
              className="scroll-to-bottom-button"
              onClick={scrollToBottom}
            >
              <Icon name="arrow down" />
            </Button>
          )}
          <div className="custom-inputs" style={{ position: 'relative', marginTop: '20px' }}>
            {replyTo && (
              <div className="reply-box">
                <span style={{ marginBottom: '5px', fontWeight: 'bold' }}>
                  {truncateString(replyTo.who_send, 15)}
                </span>
                <span style={{ marginBottom: '10px' }}>
                  {truncateString(replyTo.message, 20)}
                </span>
                <button
                  onClick={() => setReplyTo(null)}
                  style={{
                    border: 'none',
                    background: 'transparent',
                    color: '#FFFFFF',
                    fontSize: '14px',
                    alignSelf: 'flex-end',
                  }}
                >
                  Закрыть
                </button>
              </div>
            )}
            <div className="message-input-container">
              <div className="message-input-inner">
                <Button
                  icon
                  className="emoji-button"
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  disabled={!userId || !userNickname || isBanned}
                >
                  <Icon name="smile" />
                </Button>
                <TextareaAutosize
                  minRows={1}
                  maxRows={3}
                  placeholder={
                    isBanned
                      ? 'Вы забанены'
                      : !userId || !userNickname
                      ? 'Пожалуйста, зарегистрируйтесь'
                      : 'Сообщение'
                  }
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                  disabled={!userId || !userNickname || isBanned}
                  className="textarea-custom"
                />
              </div>
              <Button
                icon
                className="send-button"
                onClick={sendMessage}
                disabled={!userId || !userNickname || isBanned}
              >
                <Icon name="send" />
              </Button>
            </div>
          </div>
          {showEmojiPicker && (
            <div className="emoji-picker">
              <Picker
                data={data}
                onEmojiSelect={handleEmojiSelect}
                dynamicWidth={false}
                emojiSize={14}
                perLine={6}
                set="google"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChatComponent;
