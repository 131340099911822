import React, { useState, useEffect, useRef } from 'react';
import Map, { Marker, Popup } from 'react-map-gl';
import './MapComponent.css';
import axios from 'axios';
import { serverConnect } from '../Config/Config.js';
import { RiPoliceBadgeLine, RiPoliceCarLine, RiSendPlaneLine } from 'react-icons/ri';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { FaRegCircle, FaPlus, FaMinus } from 'react-icons/fa'; 
import Supercluster from 'supercluster';
import { easeCubic } from 'd3-ease';
import { decryptData } from '../Crypt/Crypt.js';
import 'mapbox-gl/dist/mapbox-gl.css';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';
import { Button, Input, Icon } from 'semantic-ui-react';
import CreateMarkerModal from './CreateMarkerModal';
import CreateMarkerModalRight from './CreateMarkerModalRight.js';

const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2FkcTJ3ZCIsImEiOiJjbTBsdmwzMXQwOGExMmtxemYybXMwb2M0In0.XS5fDlfABEQhvmF486mmKA';

function splitDateTime(dateTimeString) {
  const [time, date] = dateTimeString.split(' ');
  return { time, date };
}

function parseComments(commentsJson) {
  try {
    const comments = JSON.parse(commentsJson);
    if (comments.length === 0) {
      return [];
    }
    return comments.sort((a, b) => new Date(`${b.date} ${b.time}`) - new Date(`${a.date} ${a.time}`));
  } catch (error) {
    console.error('Error parsing comments JSON:', error);
    return [];
  }
}

const MapComponent = ({ mapCoordinates, isBanned }) => {
  const [viewport, setViewport] = useState({
    latitude: 55.7558,
    longitude: 37.6176,
    zoom: 10,
    bearing: 0,
    pitch: 0,
  });

  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [nickname, setNickname] = useState(null);
  const [comment, setComment] = useState('');
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [commentsLoaded, setCommentsLoaded] = useState(false);
  const superclusterRef = useRef(new Supercluster({ radius: 150, maxZoom: 12 }));
  const mapRef = useRef();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userCoordinates, setUserCoordinates] = useState({ latitude: null, longitude: null });
  // Новые состояния и обработчики для поиска и добавления меток
  const [address, setAddress] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mapCoords, setMapCoords] = useState({ latitude: '', longitude: '' });
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    lngLat: { lat: 0, lng: 0 }
  });
  const [modalMode, setModalMode] = useState('user');
  

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setTooltipText('Координаты скопированы');
      setTooltipVisible(true);
      setTimeout(() => setTooltipVisible(false), 2000);
    }).catch(() => {
      setTooltipText('Ошибка копирования');
      setTooltipVisible(true);
      setTimeout(() => setTooltipVisible(false), 2000);
    });
  };


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchMarkers = async () => {
      try {
        const response = await axios.get(`${serverConnect.server}api/map`);
        const markersData = response.data.map(marker => ({
          ...marker,
          comments: parseComments(marker.comments || ''),
          id: marker.rowid
        }));

        setMarkers(prevMarkers => {
          const newMarkerIds = markersData.map(marker => marker.id);
          const updatedMarkers = prevMarkers.filter(existingMarker => newMarkerIds.includes(existingMarker.id));
          const newMarkers = markersData.filter(newMarker =>
            !updatedMarkers.some(existingMarker => existingMarker.id === newMarker.id)
          );
          return [...updatedMarkers, ...newMarkers];
        });
      } catch (error) {
        console.error('Error fetching map data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMarkers();

    const interval = setInterval(fetchMarkers, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (mapCoordinates.latitude && mapCoordinates.longitude) {
      smoothTransition(viewport, {
        latitude: mapCoordinates.latitude,
        longitude: mapCoordinates.longitude,
        zoom: mapCoordinates.zoom || 15,
      });
    }
  }, [mapCoordinates, viewport]);

  useEffect(() => {
    if (markers.length > 0) {
      const points = markers.map((marker) => ({
        type: "Feature",
        properties: { cluster: false, id: marker.id, ...marker },
        geometry: {
          type: "Point",
          coordinates: [marker.longitude, marker.latitude],
        },
      }));

      try {
        superclusterRef.current.load(points);
      } catch (error) {
        console.error('Error loading data into Supercluster:', error);
      }
    }
  }, [markers]);

  useEffect(() => {
    const checkAuthToken = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const decryptedToken = decryptData(token);
          const response = await axios.post(`${serverConnect.server}api/user`, { token: decryptedToken });
          if (response.data.nickname) {
            setNickname(response.data.nickname);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    checkAuthToken();
  }, []);

  const handleSendComment = () => {
    if (nickname && !isBanned) {
      setIsCaptchaVisible(true);
    }
  };

  function truncateName(name, maxLength = 9) {
    return name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;
  }

  const onCaptchaResolved = async (token) => {
    setCaptchaToken(token);
    try {
      const response = await axios.post(`${serverConnect.server}api/comment`, {
        comment: {
          name: nickname,
          message: comment,
          time: new Date().toLocaleTimeString(),
          date: new Date().toLocaleDateString()
        },
        captchaToken: token,
        markerId: selectedMarker.id
      });

      if (response.data.success) {
        setSelectedMarker((prev) => ({
          ...prev,
          comments: [...prev.comments, response.data.comment]
        }));
        setComment('');
      } else {
        console.error('Error adding comment:', response.data.message);
      }
    } catch (error) {
      console.error('Error sending comment:', error);
    } finally {
      setIsCaptchaVisible(false);
    }
  };

  useEffect(() => {
    if (selectedMarker && !commentsLoaded) {
      setComment('');
      setIsCaptchaVisible(false);
      setCaptchaToken(null);
      fetchMarkerComments(selectedMarker.id);
      setCommentsLoaded(true);
    }
  }, [selectedMarker, commentsLoaded]);

  const fetchMarkerComments = async (markerId) => {
    try {
      const response = await axios.get(`${serverConnect.server}api/comments`, {
        params: { rowid: markerId }
      });
      const marker = response.data.find(marker => marker.rowid === markerId);
      if (marker) {
        setSelectedMarker(prev => ({
          ...prev,
          comments: parseComments(marker.comments)
        }));
      }
    } catch (error) {
      console.error('Error fetching marker comments:', error);
    }
  };

  const getClusterMarkers = () => {
    if (isLoading || !mapRef.current || !mapRef.current.getMap) return null;
  
    const mapboxMap = mapRef.current.getMap();
    if (!mapboxMap || !superclusterRef.current) return null;
  
    const bounds = mapboxMap.getBounds().toArray().flat();
    let clusters = [];
  
    try {
      clusters = superclusterRef.current.getClusters(bounds, viewport.zoom);
    } catch (error) {
      console.error('Error getting clusters:', error);
      return null;
    }
  
    const isMarkerInCluster = clusters.some(cluster => {
      const { cluster: isCluster } = cluster.properties;
      if (isCluster && selectedMarker) {
        return superclusterRef.current.getLeaves(cluster.id).some(leaf => leaf.properties.id === selectedMarker.id);
      }
      return false;
    });
  
    if (isMarkerInCluster) {
      setSelectedMarker(null);
    }
  
    return clusters.map((cluster) => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const { cluster: isCluster, point_count: pointCount } = cluster.properties;
  
      if (isCluster) {
        const color = getClusterColor(pointCount);
        const fontSize = Math.max(12, 24 - Math.log10(pointCount) * 5);
  
        return (
          <Marker key={`cluster-${cluster.id}`} latitude={latitude} longitude={longitude}>
            <div
              className="cluster-marker"
              style={{
                backgroundColor: color,
                width: '40px',
                height: '40px',
                lineHeight: '40px',
                borderRadius: '50%',
                textAlign: 'center',
                color: '#fff',
                fontSize: `${fontSize}px`
              }}
              onClick={() => onClusterClick(cluster)}
            >
              {pointCount}
            </div>
          </Marker>
        );
      }
  
      if (typeof latitude !== 'number' || typeof longitude !== 'number') {
        console.error('Invalid marker coordinates:', cluster.properties);
        return null;
      }
  
      return (
        <Marker
          key={cluster.properties.id}
          latitude={latitude}
          longitude={longitude}
          onClick={(event) => {
            event.originalEvent.stopPropagation();
            setSelectedMarker(cluster.properties);
            setCommentsLoaded(false);
          }}
        >
          {renderIcon(cluster.properties.icon)}
        </Marker>
      );
    });
  };
  
  const handleMove = (evt) => {
    setViewport(evt.viewState);
    // Скрываем контекстное меню при движении карты
    setContextMenu((prevContextMenu) => ({ ...prevContextMenu, visible: false }));
  };

  const smoothTransition = (startViewport, endViewport, duration = 2000) => {
    const startTime = performance.now();
    const animate = () => {
      const now = performance.now();
      const time = Math.min((now - startTime) / duration, 1);
      const easedTime = easeCubic(time);

      const latitude = startViewport.latitude + (endViewport.latitude - startViewport.latitude) * easedTime;
      const longitude = startViewport.longitude + (endViewport.longitude - startViewport.longitude) * easedTime;
      const zoom = startViewport.zoom + (endViewport.zoom - startViewport.zoom) * easedTime;

      setViewport((prev) => ({ ...prev, latitude, longitude, zoom }));

      if (time < 1) {
        requestAnimationFrame(animate);
      }
    };
    requestAnimationFrame(animate);
  };

  const onClusterClick = (cluster) => {
    try {
      const expansionZoom = Math.min(
        superclusterRef.current.getClusterExpansionZoom(cluster.id),
        20
      );

      smoothTransition(viewport, {
        latitude: cluster.geometry.coordinates[1],
        longitude: cluster.geometry.coordinates[0],
        zoom: expansionZoom,
      }, 1000);
    } catch (error) {
      console.error('Error expanding cluster:', error);
    }
  };

  const renderIcon = (icon) => {
    const iconStyle = { color: '#6161f9', fontSize: '24px' };

    switch(icon) {
      case 'police':
        return <RiPoliceBadgeLine style={iconStyle} />;
      case 'police-car':
        return <RiPoliceCarLine style={iconStyle} />;
      default:
        return <div style={iconStyle}>?</div>;
    }
  };

  const getClusterColor = (count) => {
    const minColor = [255, 0, 102, 0.6];
    const maxColor = [255, 0, 102, 1.0];
    const maxCount = 100;

    const factor = Math.min(count / maxCount, 1);
    const [r, g, b, a] = minColor.map((c, i) => c + (maxColor[i] - c) * factor);
    return `rgba(${r},${g},${b},${a})`;
  };

  const getBorderClass = (dangerLevel) => {
    switch (dangerLevel) {
      case 1:
        return 'border-danger-1';
      case 2:
        return 'border-danger-2';
      case 3:
        return 'border-danger-3';
      case 4:
        return 'border-danger-4';
      case 5:
        return 'border-danger-5';
      default:
        return '';
    }
  };

  const getBackgroundColor = (dangerLevel) => {
    switch (dangerLevel) {
      case 1:
        return 'rgb(0, 128, 0)';
      case 2:
        return 'rgb(154, 205, 50)';
      case 3:
        return 'rgb(238, 177, 35)';
      case 4:
        return 'rgb(255, 165, 0)';
      case 5:
        return 'rgb(217, 26, 26)';
      default:
        return 'rgba(0, 0, 0, 0.5)';
    }
  };

  const renderCreatorName = (name) => {
    const maxLength = 7;
    return (
      <span className="creator-name" title={name}>
        {name.length > maxLength ? `${name.substring(0, maxLength)}...` : name}
      </span>
    );
  };

  // Обработчики для поиска и добавления метки
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleAddressSearch = async () => {
    if (address.trim() === '') return;

    try {
      const response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${MAPBOX_TOKEN}&language=ru`);
      if (response.data && response.data.features && response.data.features.length > 0) {
        const { center } = response.data.features[0];
        setMapCoords({ longitude: center[0], latitude: center[1], zoom: 15 });
        // Перемещаем карту к найденному адресу
        smoothTransition(viewport, {
          latitude: center[1],
          longitude: center[0],
          zoom: 18,
        });
      } else {
        toast.info('Адрес не найден');
        setMapCoords({ longitude: '', latitude: '' });
      }
    } catch (error) {
      console.error('Ошибка при поиске адреса', error);
      toast.error('Ошибка при поиске адреса');
      setMapCoords({ longitude: '', latitude: '' });
    }
  };

  const handleMarkerClick = () => {
    if (!nickname || isBanned) {
      return;
    }
    setUserCoordinates({
      latitude: viewport.latitude,
      longitude: viewport.longitude,
    });
    setModalMode('user'); // Устанавливаем режим модального окна в 'user'
    setIsModalOpen(true);
  };


  const closeModal = () => {
    setIsModalOpen(false);
    // Убираем сброс modalMode
  };

  const handleContextMenu = (event) => {
    event.originalEvent.preventDefault();

    const { point: { x, y }, lngLat } = event;

    setContextMenu({
      visible: true,
      x,
      y,
      lngLat
    });
  };
  
  
  // Обработчик создания метки
  const handleCreateMarker = () => {
    setContextMenu({ ...contextMenu, visible: false });

    if (!nickname || isBanned) {
      return;
    }
    setUserCoordinates({
      latitude: contextMenu.lngLat.lat,
      longitude: contextMenu.lngLat.lng,
    });
    setModalMode('context'); 
    setIsModalOpen(true);
  };
  
  const handleCopyCoordinates = () => {
    const { lat, lng } = contextMenu.lngLat;
    copyToClipboard(`${lat.toFixed(6)}; ${lng.toFixed(6)}`);
    setContextMenu({ ...contextMenu, visible: false });
  };

  const handleZoomIn = () => {
    setViewport((prev) => ({
      ...prev,
      zoom: Math.min(prev.zoom + 1, 20),
    }));
  };

  const handleZoomOut = () => {
    setViewport((prev) => ({
      ...prev,
      zoom: Math.max(prev.zoom - 1, 0), 
    }));
  };


  return (
    <div className="map-container">
      <ToastContainer />
      <div className={`map-box ${isCaptchaVisible ? 'captcha-visible' : ''}`}>
        <Map
          ref={mapRef}
          {...viewport}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/sadq2wd/cm0r4u6h900md01qo0rjy2161"
          mapboxAccessToken={MAPBOX_TOKEN}
          onMove={handleMove}
          locale='ru'
          onContextMenu={handleContextMenu}
          onClick={() => {
            setContextMenu({ ...contextMenu, visible: false });
            setSelectedMarker(null); // Close any open popups
          }} // Закрытие контекстного меню при клике
        >
          {contextMenu.visible && (
          <div
            className="context-menu-map"
            style={{ top: contextMenu.y, left: contextMenu.x }}
          >
            <button onClick={handleCreateMarker}>Создать метку <Icon name='large map marker alternate' className='context-menu-icon'/></button>
            <button onClick={handleCopyCoordinates}>Скопировать <Icon name='large copy' className='context-menu-icon'/></button>
          </div>
        )}
        
          {getClusterMarkers()}
          {selectedMarker && selectedMarker.latitude && selectedMarker.longitude && (
            <Popup
            latitude={selectedMarker.latitude}
            longitude={selectedMarker.longitude}
            onClose={() => setSelectedMarker(null)}
            closeOnClick={false}
            anchor='bottom'
            offset={[0, -10]}
            maxWidth="400px"
          >
            <div className={`popup-content ${isCaptchaVisible ? 'fade-out' : ''}`}>
              <div className={`popup-details ${getBorderClass(selectedMarker.danger)}`}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div
                    className="creator"
                    style={{ backgroundColor: getBackgroundColor(selectedMarker.danger) }}
                  >
                    {renderCreatorName(selectedMarker.createBy)}
                  </div>
                  <div className="date-time-container">
                    <span className="time" style={{ backgroundColor: getBackgroundColor(selectedMarker.danger) }}>
                      {splitDateTime(selectedMarker.date).time}
                    </span>
                    <span className="date" style={{ backgroundColor: getBackgroundColor(selectedMarker.danger) }}>
                      {splitDateTime(selectedMarker.date).date}
                    </span>
                  </div>
                </div>
                <div style={{ marginTop: '15px' }}>
                  <h4 style={{ color: 'black', display: 'inline' }}>
                    {isExpanded ? selectedMarker.message : `${selectedMarker.message.substring(0, 150)}`}
                    {!isExpanded && selectedMarker.message.length > 150 && '...'}
                    {selectedMarker.message.length > 150 && (
                      <button onClick={() => setIsExpanded(!isExpanded)} style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '1.2em', color: '#007BFF', padding: '0', marginLeft: '5px' }}>
                        {isExpanded ? <IoIosArrowUp style={{color: 'black'}}/> : <IoIosArrowDown style={{color: 'black'}}/>}
                      </button>
                    )}
                  </h4>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                  <div
                    className="coordinates"
                    style={{ backgroundColor: getBackgroundColor(selectedMarker.danger), cursor: 'pointer', maxWidth: '100px' }}
                    onClick={() => copyToClipboard(`${selectedMarker.latitude}, ${selectedMarker.longitude}`)}
                    title={`${selectedMarker.latitude}; ${selectedMarker.longitude}`}  /* Отображает полный текст при наведении */
                  >
                    {`${selectedMarker.latitude}; ${selectedMarker.longitude}`}
                  </div>
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <span style={{ 
                      marginRight: '5px', 
                      padding: '2px 8px',
                      borderRadius: '10px',
                      backgroundColor: getBackgroundColor(selectedMarker.danger),
                      color: 'white' 
                    }}>
                      Danger lvl:
                    </span>
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <FaRegCircle style={{ color: getBackgroundColor(selectedMarker.danger) }} size={25}/>
                      <span style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '12px',
                        color: getBackgroundColor(selectedMarker.danger)
                      }}>
                        {selectedMarker.danger}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="comment-section">
                <input
                  type="text"
                  placeholder={isBanned ? "Вы забанены!" : "Напишите свой комментарий..."} // Изменяем placeholder в зависимости от статуса бана
                  value={isBanned ? "" : comment} // Очищаем поле, если пользователь забанен
                  onChange={(e) => setComment(e.target.value)}
                  style={{ width: '100%', color: nickname && !isBanned ? 'black' : 'gray' }}
                  disabled={!nickname || isBanned} // Отключаем input, если пользователь забанен
                  title={!nickname ? 'Пожалуйста, авторизуйтесь' : isBanned ? 'Вы забанены!' : ''}
                  maxLength={500}
                />
                <button
                  onClick={handleSendComment}
                  disabled={!nickname || isBanned} // Отключаем кнопку отправки, если пользователь забанен
                  title={!nickname ? 'Пожалуйста, авторизуйтесь' : isBanned ? 'Вы забанены!' : ''}
                  style={{ padding: '5px', borderRadius: '50%', backgroundColor: 'transparent', border: 'none', cursor: nickname && !isBanned ? 'pointer' : 'not-allowed' }}
                >
                  <RiSendPlaneLine size={24} color={nickname && !isBanned ? 'gray' : 'lightgray'} />
                </button>
              </div>
              <div className="popup-comments" style={{marginTop: '10px'}}>
                {selectedMarker.comments.length > 0 ? (
                  selectedMarker.comments.map((comment, index) => (
                    <div key={index} className="popup-comment" style={{marginTop: '10px'}}>
                      <div className="comment-header">
                        <span className="comment-name">{truncateName(comment.name)}</span>
                        <span className="comment-time-date">{`${comment.time} ${comment.date}`}</span>
                      </div>
                      <div className="comment-message">{comment.message}</div>
                    </div>
                  ))
                ) : (
                  <div className="no-comments">На данный момент комментариев нету</div>
                )}
              </div>
            </div>
          </Popup>
          )}
          {isCaptchaVisible && (
              <div className="captcha-overlay">
                <div className="captcha-container">
                  <HCaptcha
                    sitekey="dd123fb4-e0b8-4636-a408-3734ed7ae054"
                    onVerify={onCaptchaResolved}
                  />
                  <button
                    onClick={() => setIsCaptchaVisible(false)}
                    className="captcha-cancel-button"
                  >
                    Отмена
                  </button>
                </div>
              </div>
            )}
          {tooltipVisible && (
            <div className="tooltip" style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', backgroundColor: '#333', color: '#fff', padding: '5px 10px', borderRadius: '5px', fontSize: '14px' }}>
              {tooltipText}
            </div>
          )}
        </Map>

        <div className="zoom-controls">
          <button className="zoom-button" onClick={handleZoomIn} aria-label="Увеличить масштаб">
            <FaPlus />
          </button>
          <button className="zoom-button" onClick={handleZoomOut} aria-label="Уменьшить масштаб">
            <FaMinus />
          </button>
        </div>
        {/* Элементы управления поверх карты */}
        <div className="map-controls">

          <Input
            icon={{ name: 'search', link: true, onClick: handleAddressSearch }}
            placeholder="Введите адрес..."
            className="custom-input"
            value={address}
            onChange={handleAddressChange}
            onKeyDown={(e) => e.key === 'Enter' && handleAddressSearch()}
          />
          {windowWidth > 400 ? (
            <Button
              className="gradient-button-map"
              disabled={!nickname || isBanned}
              data-tip={
                isBanned
                  ? 'Вам запрещено создавать метки'
                  : !nickname
                  ? 'Пожалуйста, авторизуйтесь'
                  : ''
              }
              onClick={handleMarkerClick}
            >
              Создать метку
            </Button>
          ) : (
            <Button
              icon
              className="gradient-button-map icon-button"
              disabled={!nickname || isBanned}
              data-tip={
                isBanned
                  ? 'Вам запрещено создавать метки'
                  : !nickname
                  ? 'Пожалуйста, авторизуйтесь'
                  : ''
              }
              onClick={handleMarkerClick}
            >
              <Icon name="plus" />
            </Button>
          )}
          <Tooltip place="top" type="dark" effect="float" />
        </div>
        <CreateMarkerModal
          isOpen={isModalOpen}
          onClose={closeModal}
          userCoordinates={userCoordinates}
          modalMode={modalMode} // Передаем modalMode здесь
          nickname={nickname}
        />
        <div className="map-title">Red Map</div>
        <div className="zoom-level">
          Zoom Level: {viewport.zoom.toFixed(2)}, 
          Широта: {viewport.latitude.toFixed(6)}, 
          Долгота: {viewport.longitude.toFixed(6)}
        </div>
      </div>
    </div>
  );
};

export default MapComponent;