// App.js
import React from 'react';
import './App.css';
import MainPage from './Main/Main';
import Reg from './Registration/Reg';
import Login from './Login/Login';
import MapPage from './Main/MapPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PublicRoute from './Components/PublicRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/registration" element={<PublicRoute />}>
          <Route path="/registration" element={<Reg />} />
        </Route>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
