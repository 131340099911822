import React, { useState, useRef } from 'react';
import './Login.css';
import { Input, Button } from 'semantic-ui-react';
import { FaWarehouse } from "react-icons/fa6";
import { serverConnect } from "../Config/Config.js";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { encryptData } from '../Crypt/Crypt.js';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const Login = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        username: '',
        password: '',
    });

    const [isFormValid, setIsFormValid] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const navigate = useNavigate();
    const hcaptchaRef = useRef(null);

    const validateField = (name, value) => {
        let error = '';

        switch (name) {
            case 'username':
                if (value.length < 5) {
                    error = 'Длина логина должна быть не менее 5 символов';
                }
                break;
            case 'password':
                if (value.length < 7) {
                    error = 'Длина пароля должна быть не менее 7 символов';
                }
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    const validateForm = (data, token) => {
        const isValid =
            data.username.length >= 5 &&
            data.password.length >= 7 &&
            token;

        setIsFormValid(isValid);
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => {
            const newFormData = { ...prevFormData, [name]: value };
            validateField(name, value);
            validateForm(newFormData, captchaToken); // Валидация формы после обновления состояния
            return newFormData;
        });
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);
    };

    const handleCaptchaVerify = (token) => {
        setCaptchaToken(token);
        validateForm(formData, token); // Проверка состояния формы после получения токена капчи
    };

    const handleSubmit = async () => {
        if (isFormValid) {
            if (!captchaToken) {
                toast.error("Пожалуйста, пройдите капчу!");
                return;
            }

            try {
                const response = await fetch(`${serverConnect.server}api/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ ...formData, 'h-captcha-response': captchaToken })
                });
                const result = await response.json();

                if (result.message === "Login successful") {
                    const encryptedToken = encryptData(result.token);
                    localStorage.setItem('authToken', encryptedToken);
                    toast.success("Вход успешен!", {
                        onClose: () => navigate('/'),
                        autoClose: 1000
                    });
                } else {
                    toast.error(result.message || "Ошибка входа");
                    hcaptchaRef.current.resetCaptcha(); // Сбрасываем капчу
                }
            } catch (error) {
                toast.error("Произошла ошибка при входе");
                hcaptchaRef.current.resetCaptcha(); // Сбрасываем капчу
            }
        } else {
            toast.error("Пожалуйста, заполните все поля!");
        }
    };

    return (
        <div className="main-login">
            <ToastContainer />
            <div className="container-login">
                <div className="title-container">
                    <a href="/" className="title-link">
                        <h1 className="login-title">
                            <FaWarehouse color="#C3073F" size={33} style={{ marginRight: '10px' }} /> RedMap
                        </h1>
                    </a>
                </div>
                <div className="input-container">
                    <label className="input-label">Логин</label>
                    <Input
                        icon='user'
                        iconPosition='left'
                        placeholder='Введите логин...'
                        className="custom-input"
                        name="username"
                        value={formData.username}
                        onInput={handleInput}
                        onBlur={handleBlur}
                        maxLength="50"
                    />
                    {errors.username && <span className="error-text">{errors.username}</span>}
                </div>
                <div className="input-container">
                    <label className="input-label">Пароль</label>
                    <Input
                        icon='lock'
                        iconPosition='left'
                        placeholder='Введите пароль...'
                        className="custom-input"
                        type="password"
                        name="password"
                        value={formData.password}
                        onInput={handleInput}
                        onBlur={handleBlur}
                        maxLength="50"
                    />
                    {errors.password && <span className="error-text">{errors.password}</span>}
                </div>
                <div className="hcaptcha-container">
                    <HCaptcha
                        sitekey="dd123fb4-e0b8-4636-a408-3734ed7ae054" // Замените на ваш ключ сайта
                        onVerify={handleCaptchaVerify}
                        ref={hcaptchaRef}
                    />
                </div>
                <Button
                    className="submit-button"
                    disabled={!isFormValid}
                    onClick={handleSubmit}
                >
                    Войти
                </Button>
                <div className="register-link">
                    <span>Нет аккаунта?</span>
                    <a href="/registration" className="register-link-text">Зарегистрироваться</a>
                </div>
            </div>
        </div>
    );
};

export default Login;
