import React, { useState, useEffect } from 'react';
import './Main.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { decryptData } from '../Crypt/Crypt.js';
import { serverConnect } from '../Config/Config.js';
import NavBarComponent from '../Components/NavbarComponent.js';
import FooterComponent from '../Components/FooterComponent.js'; // Импортируем FooterComponent
import {
  RiBtcFill,
  RiMenu2Fill,
  RiExchangeDollarFill,
  RiTelegramLine
} from "react-icons/ri";
import {
  PiChatCircleDotsBold,
  PiIntersectSquareBold,
} from "react-icons/pi";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlineVpnKey,
  MdOutlineChangeCircle
} from "react-icons/md";
import {
  FaCrown,
  FaShield,
} from "react-icons/fa6";
import { TiWorld } from "react-icons/ti";
import { TbBrandHipchat } from "react-icons/tb";
import { LuLock } from "react-icons/lu";
import { Button, Dropdown, Icon } from 'semantic-ui-react';

import ChatComponent from '../Components/ChatComponent.js';

function shortenDomain(domain) {
  return domain.length > 15 ? `${domain.slice(0, 15)}...` : domain;
}

const MainPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [cryptoData, setCryptoData] = useState([]);
  const [btcPrice, setBtcPrice] = useState({ usd: null, rub: null });
  const [iconState, setIconState] = useState('close');
  const [nickname, setNickname] = useState(null);
  const [userId, setUserId] = useState(null); // Добавляем состояние для userId
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(JSON.parse(localStorage.getItem('chatOpen')) || false);
  const [isArrowUp, setIsArrowUp] = useState(false);
  const [banners, setBanners] = useState([]);
  const [expandedSections, setExpandedSections] = useState({});
  const [catalogs, setCatalogs] = useState([]);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setIconState(menuOpen ? 'close' : 'open');
  };

  const handleMapClick = () => {
    navigate('/map');
  };

  const handleChatToggle = () => {
    setIsChatOpen(prevState => {
      const newState = !prevState;
      localStorage.setItem('chatOpen', JSON.stringify(newState));
      return newState;
    });
    setIsArrowUp(!isArrowUp);
  };

  const toggleSection = (section) => {
    setExpandedSections(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000 && menuOpen) {
        setMenuOpen(false);
        setIconState('close');
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [menuOpen]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [usdResponse, rubResponse] = await Promise.all([
          axios.get('https://api.coingecko.com/api/v3/coins/markets', {
            params: {
              vs_currency: 'usd',
              ids: 'bitcoin,monero,litecoin,tether,ethereum',
              order: 'market_cap_desc',
              per_page: 5,
              page: 1,
              sparkline: false
            }
          }),
          axios.get('https://api.coingecko.com/api/v3/coins/markets', {
            params: {
              vs_currency: 'rub',
              ids: 'bitcoin,monero,litecoin,tether,ethereum',
              order: 'market_cap_desc',
              per_page: 5,
              page: 1,
              sparkline: false
            }
          })
        ]);

        const usdData = usdResponse.data;
        const rubData = rubResponse.data;

        const combinedData = usdData.map((coin, index) => ({
          ...coin,
          price_rub: rubData[index].current_price
        }));

        setCryptoData(combinedData);
        const btcData = combinedData.find(coin => coin.id === 'bitcoin');
        if (btcData) {
          setBtcPrice({
            usd: btcData.current_price.toFixed(2),
            rub: btcData.price_rub.toFixed(2)
          });
        }
      } catch (error) {
        console.error('Error fetching the crypto data', error);
      } finally {
        setIsLoading(false);
        setIsFirstLoad(false);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 1800000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const checkAuthToken = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const decryptedToken = decryptData(token);
          const response = await axios.post(`${serverConnect.server}api/user`, { token: decryptedToken });
          if (response.data.nickname) {
            setNickname(response.data.nickname);
            setUserId(response.data.id); // Сохраняем userId в состояние
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken');
          } else {
            console.error('Error fetching user data', error);
          }
        }
      }
      setIsUserLoading(false);
    };

    checkAuthToken();
  }, []);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get(`${serverConnect.server}api/banners`);
        if (response.data.success) {
          setBanners(response.data.banners);
        }
      } catch (error) {
        console.error('Error fetching banners data', error);
      }
    };

    fetchBanners();
  }, []);

  useEffect(() => {
    const fetchCatalogs = async () => {
      try {
        const response = await axios.get(`${serverConnect.server}api/catalog`);
        if (response.data.success) {
          setCatalogs(response.data.catalogs);
        }
      } catch (error) {
        console.error('Error fetching catalogs data', error);
      }
    };

    fetchCatalogs();
  }, []);

  const handleRegistrationClick = () => navigate('/registration');
  const handleLoginClick = () => navigate('/login');

  const renderBanners = () => banners.map((banner, index) => (
    <div
      className={`banner-block ${index === 0 ? 'gold' : index === 1 ? 'silver' : index === 2 ? 'bronze' : ''}`}
      key={banner.id}
    >
      {banner.status === 1 ? (
        
        <div className='empty-banner'>
          {index === 0 && <FaCrown className="crown-icon" />}
          Свободное место
        </div>
      ) : (
        <>
          {index === 0 && <FaCrown className="crown-icon" />}
          <img src={require(`../Assets/banners/${banner.img}`)} alt={banner.name} className="circle-image" />
          <div className="text-container">
            <div className="image-text">{banner.name}</div>
            <div className="image-text-content">{banner.text}</div>
          </div>
          <div className="link-container">
            {banner.web && (
              <a href={banner.web} target="_blank" rel="noopener noreferrer" className="link-button">
                <PiIntersectSquareBold className="icon" />
                {shortenDomain(banner.web)}
              </a>
            )}
            {banner.tor && banner.tor !== 'None' && (
              <a href={banner.tor} target="_blank" rel="noopener noreferrer" className="link-button">
                <FaShield className="icon" />
                {shortenDomain(banner.tor)}
              </a>
            )}
          </div>
        </>
      )}
    </div>
  ));

  const renderCatalogs = (categoryId, categoryName, categoryIcon) => (
    <div id={categoryId} className={`ag-container ${expandedSections[categoryId] ? 'expanded' : ''}` }>
      <div className="ag-container-header">
        <span style={{ fontSize: '22px', zIndex: 99999 }}>
          {React.createElement(categoryIcon, { size: 40, style: { marginRight: '10px' } })}{categoryName}
        </span>
        <MdOutlineKeyboardArrowDown className="arrow-icon" size={30} onClick={() => toggleSection(categoryId)} style={{ cursor: "pointer" }} />
      </div>
      <div className="ag-container-content">
        {catalogs.filter(catalog => catalog.id === categoryId).map((catalog, index) => (
          <div className='ag-content' key={index}>
            <h2>{catalog.name}</h2>
            <div className='text-content-ag'>{catalog.text}</div>
            <div className="button-group">
              <Dropdown text='Clears' icon='world' labeled button className='icon'>
                <Dropdown.Menu className='custom-dropdown-menu-button'>
                  {catalog.links.length > 0 ? (
                    catalog.links.map((link, linkIndex) => (
                      <Dropdown.Item
                        key={linkIndex}
                        text={link.name}
                        onClick={() => window.open(link.link, "_blank")}
                      />
                    ))
                  ) : (
                    <Dropdown.Item text="Нет активных ссылок" disabled />
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown text='Onion' icon='fork' labeled button className='icon'>
                <Dropdown.Menu className='custom-dropdown-menu-button'>
                  {catalog.tor.length > 0 ? (
                    catalog.tor.map((torLink, torIndex) => (
                      <Dropdown.Item
                        key={torIndex}
                        text={torLink.name}
                        onClick={() => window.open(torLink.link, "_blank")}
                      />
                    ))
                  ) : (
                    <Dropdown.Item text="Нет активных ссылок" disabled />
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      {isFirstLoad && isLoading ? (
        <div className="loading-screen">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <NavBarComponent
            nickname={nickname}
            isUserLoading={isUserLoading}
            toggleMenu={toggleMenu}
            menuOpen={menuOpen}
            iconState={iconState}
            handleKebabClick={() => alert('burger menu')}
            handleMapClick={() => navigate('/map')}
            handleLoginClick={handleLoginClick}
            handleRegistrationClick={handleRegistrationClick}
          />
          <div className="main">
            <div className="main-content">
              <div className="crypto-block">
                <div className="left-container" style={{ marginRight: '30px' }}>
                  <RiBtcFill style={{ marginRight: '5px', color: "white" }} />
                  <span>${btcPrice.usd || 'Loading...'}</span>
                </div>
                <div className="right-container">
                  <div className="crypto-ticker">
                    <div className="ticker">
                      <div className="ticker-move">
                        {cryptoData.concat(cryptoData).map((coin, index) => (
                          <div key={coin.id + index} className="crypto-item">
                            <img src={coin.image} alt={coin.name} className="crypto-icon" />
                            <div className="crypto-prices">
                              <span>${coin.current_price.toFixed(2)}</span>
                              <span> / </span>
                              <span>₽{coin.price_rub.toFixed(2)}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`chat-container ${!isChatOpen ? 'closed' : ''}`} style={{ height: isChatOpen ? '600px' : '75px' }}>
                <h2 style={{ display: 'flex', alignItems: 'center' }}>
                  <PiChatCircleDotsBold size={30} style={{ marginRight: '10px' }} /> Red Map / Chat
                </h2>
                <div className='chat-utils-icon' onClick={handleChatToggle}>
                  <MdOutlineKeyboardArrowDown
                    size={30}
                    color="white"
                    className={isArrowUp ? 'rotate-up' : 'rotate-down'}
                  />
                </div>
                <div className='chat-content'>
                    <ChatComponent userNickname={nickname} userId={userId} /> {/* Передаем userId */}
                </div>
              </div>
              <div onClick={handleMapClick} className="map-container-page-main">
                <img src={require('../Assets/map.png')} alt="Map" className="map-image" />
              </div>
              <div id="banners" className="banner-container">
                {renderBanners()}
              </div>
              {renderCatalogs('marketplace', 'Маркетплейсы', TiWorld)}
              {renderCatalogs('forum', 'Форумы', TbBrandHipchat)}
              {renderCatalogs('exchanger', 'Обменники', RiExchangeDollarFill)}
              {renderCatalogs('telegram', 'Telegram каналы', RiTelegramLine)}
              {renderCatalogs('vpn', 'VPN сервисы', LuLock)}
              {renderCatalogs('filechange', 'Файлообменники', MdOutlineChangeCircle)}
              <h1></h1>
            </div>
          </div>
          <FooterComponent /> {/* Вставляем FooterComponent */}

        </>
      )}
    </div>
  );
};

export default MainPage;
