// CreateMarkerModal.js
import React, { useState, useEffect, useRef } from 'react';
import './CreateMarkerModal.css';
import axios from 'axios';
import { Input, Button, Icon } from 'semantic-ui-react';
import { MdLocalPolice } from "react-icons/md";
import { PiPoliceCarBold } from "react-icons/pi";
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Bobik from '../Assets/Бобик.png';
import { serverConnect } from "../Config/Config.js";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Swal from 'sweetalert2';

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#c3073f',
      main: '#a8234b',
      dark: '#6e1630',
      contrastText: '#fff',
    },
  },
});

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];

const CustomSlider = styled(Slider)({
  '& .MuiSlider-thumb': {
    width: 62,
    height: 38,
    backgroundColor: 'transparent',
    backgroundImage: `url(${Bobik})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: 'none',
    },
    marginLeft: 10,
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#a8234b',
    height: 5,
    width: 5,
    borderRadius: '50%',
  },
  '& .MuiSlider-markLabel': {
    fontSize: '1rem',
    color: '#fff',
    marginTop: 5,
  },
});

const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2FkcTJ3ZCIsImEiOiJjbTBsdmwzMXQwOGExMmtxemYybXMwb2M0In0.XS5fDlfABEQhvmF486mmKA';
const HCAPTCHA_SITE_KEY = 'dd123fb4-e0b8-4636-a408-3734ed7ae054'

const CreateMarkerModal = ({ isOpen, onClose, userCoordinates, modalMode, nickname }) => {
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [comment, setComment] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('полицейский');
  const [dangerLevel, setDangerLevel] = useState(2);
  const [isAnonymous, setIsAnonymous] = useState(false);

  const [addressError, setAddressError] = useState('');
  const [latitudeError, setLatitudeError] = useState('');
  const [longitudeError, setLongitudeError] = useState('');
  const [latLngError, setLatLngError] = useState('');
  const [latLng, setLatLng] = useState('');
  const [commentError, setCommentError] = useState('');

  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);


  const handleSliderChange = (event, newValue) => {
    setDangerLevel(newValue);
  };

  const fetchCoordinates = async (address) => {
    try {
      const encodedAddress = encodeURIComponent(address);
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedAddress}.json?access_token=${MAPBOX_TOKEN}&limit=1&language=ru`
      );
      if (response.data && response.data.features && response.data.features.length > 0) {
        const [lng, lat] = response.data.features[0].center;
        return { latitude: lat, longitude: lng };
      } else {
        throw new Error('Координаты не найдены для данного адреса.');
      }
    } catch (error) {
      console.error('Ошибка при получении координат:', error);
      throw error;
    }
  };
  
  

  useEffect(() => {
    if (isOpen && userCoordinates) {
      if (modalMode === 'user') {
        const getAddress = async () => {
          try {
            const fetchedAddress = await fetchAddress(userCoordinates.latitude, userCoordinates.longitude);
            setAddress(fetchedAddress);
          } catch (error) {
            console.error('Ошибка при получении адреса:', error);
            // Опционально установите состояние ошибки или отобразите сообщение пользователю
            setAddress('Ошибка при получении адреса');
          }
        };
        getAddress();
      } else if (modalMode === 'context') {
        // Преобразуем числа в строки
        setLatitude(userCoordinates.latitude.toString());
        setLongitude(userCoordinates.longitude.toString());
      }
    }
  }, [isOpen, userCoordinates, modalMode]);
  
  useEffect(() => {
    if (!isOpen) {
      setAddress('');
      setLatitude('');
      setLongitude('');
      setComment('');
      setSelectedCategory('полицейский');
      setDangerLevel(1);
      setIsAnonymous(false); // Сброс состояния чекбокса при закрытии

      setAddressError('');
      setLatitudeError('');
      setLongitudeError('');
      setCommentError('');

      setLatLng('');
      setLatLngError('');

      setShowCaptcha(false);
      setCaptchaToken(null);
      if (captchaRef.current) {
        captchaRef.current.resetCaptcha();
      }
    }
  }, [isOpen]);


  
  const validateAddress = () => {
    if (!address.trim()) {
      setAddressError('Адрес не может быть пустым.');
      return false;
    } else if (address.trim().length < 3) {
      setAddressError('Адрес должен содержать не менее 3 символов.');
      return false;
    } else if (address.trim().length > 300) {
      setAddressError('Адрес не может превышать 300 символов.');
      return false;
    } else {
      setAddressError('');
      return true;
    }
  };

  const validateLatitude = () => {
    if (!latitude.trim()) {
      setLatitudeError('Широта не может быть пустой.');
      return false;
    }
    const latNum = parseFloat(latitude);
    if (isNaN(latNum)) {
      setLatitudeError('Широта должна быть числом.');
      return false;
    }
    if (latNum < -90 || latNum > 90) { // Corrected latitude range
      setLatitudeError('Широта должна быть в диапазоне от -90 до 90.');
      return false;
    }
    if (latitude.trim().length > 100) {
      setLatitudeError('Широта не может превышать 100 символов.');
      return false;
    }
    setLatitudeError('');
    return true;
  };

  const validateLongitude = () => {
    if (typeof longitude !== 'string') {
        setLongitudeError('Некорректный формат долготы.');
        return false;
    }
    if (!longitude.trim()) {
        setLongitudeError('Долгота не может быть пустой.');
        return false;
    }
    const lngNum = parseFloat(longitude);
    if (isNaN(lngNum)) {
        setLongitudeError('Долгота должна быть числом.');
        return false;
    }
    if (lngNum < -180 || lngNum > 180) { // Corrected longitude range
      setLongitudeError('Долгота должна быть в диапазоне от -180 до 180.');
      return false;
    }
    if (longitude.trim().length > 100) {
        setLongitudeError('Долгота не может превышать 100 символов.');
        return false;
    }
    setLongitudeError('');
    return true;
};

  const validateComment = () => {
    if (!comment.trim()) {
      setCommentError('Комментарий не может быть пустым.');
      return false;
    } else if (comment.trim().length < 20) {
      setCommentError('Комментарий должен содержать не менее 20 символов.');
      return false;
    } else if (comment.trim().length > 1000) {
      setCommentError('Комментарий не может превышать 1000 символов.');
      return false;
    } else {
      setCommentError('');
      return true;
    }
  };

  const handleSubmit = async () => {
    // Проверка всех полей
    const isAddressValid = modalMode === 'user' ? validateAddress() : true;
    const isLatitudeValid = modalMode === 'context' ? validateLatitude() : true;
    const isLongitudeValid = modalMode === 'context' ? validateLongitude() : true;
    const isCommentValid = validateComment();

    if (!isAddressValid || !isLatitudeValid || !isLongitudeValid || !isCommentValid) {
      return; // Прекращаем отправку, если есть ошибки
    }

    try {
      let finalLatitude = latitude;
      let finalLongitude = longitude;
      let finalAddress = address;

      if (modalMode === 'user') {
        if (!address.trim()) {
          Swal.fire({
            icon: 'info',
            title: 'Информация',
            text: 'Пожалуйста, введите адрес.',
          });
          return;
        }
        // Получаем координаты по адресу
        const coords = await fetchCoordinates(address);
        finalLatitude = coords.latitude;
        finalLongitude = coords.longitude;

        // Обновляем строку latLng для дальнейшего использования в captcha
        setLatLng(`${finalLatitude}; ${finalLongitude}`);

        // Обновляем поля широты и долготы
        setLatitude(finalLatitude.toString());
        setLongitude(finalLongitude.toString());
      } else if (modalMode === 'context') {
        // Валидация координат уже выполнена выше
      }

      // Вместо alert, запускаем капчу
      setShowCaptcha(true);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ошибка',
        text: 'Не удалось получить координаты. Пожалуйста, проверьте введённый адрес.',
      });
    }
  };

  const handleAddressBlur = async () => {
    const isValid = validateAddress();
    if (isValid) {
      try {
        const coords = await fetchCoordinates(address);
        setLatLng(`${coords.latitude}; ${coords.longitude}`);
        setLatLngError('');
      } catch (error) {
        setLatLng('');
        setLatLngError('Не удалось получить координаты по адресу.');
      }
    } else {
      setLatLng('');
    }
  };

  const handleCaptchaVerify = async (token) => {
    try {
      setCaptchaToken(token);
  
      let latitudeParsed, longitudeParsed;
  
      if (modalMode === 'user') {
        // Парсим строку latLng: удаляем пробелы и разделяем по ';'
        const latLngClean = latLng.replace(/\s+/g, ''); // Удаляем все пробелы
        const [latStr, lngStr] = latLngClean.split(';');
  
        // Проверяем, что разделение прошло успешно
        if (!latStr || !lngStr) {
          Swal.fire({
            icon: 'question',
            title: 'Ошибка',
            text: 'Пожалуйста, введите корректные значения широты и долготы в формате "Широта; Долгота".',
          });
          return;
        }
  
        // Преобразуем строки в числа
        latitudeParsed = parseFloat(latStr);
        longitudeParsed = parseFloat(lngStr);
      } else if (modalMode === 'context') {
        // Используем отдельные поля широты и долготы
        latitudeParsed = parseFloat(latitude);
        longitudeParsed = parseFloat(longitude);
  
        // Проверяем, что значения присутствуют
        if (!latitude || !longitude) {
          Swal.fire({
            icon: 'question',
            title: 'Ошибка',
            text: 'Пожалуйста, введите значения широты и долготы.',
          });
          return;
        }
      } else {
        Swal.fire({
          icon: 'question',
          title: 'Ошибка',
          text: 'Неизвестный режим модального окна.',
        });
        return;
      }
  
      // Проверяем валидность числовых значений
      if (isNaN(latitudeParsed) || isNaN(longitudeParsed)) {
        Swal.fire({
          icon: 'question',
          title: 'Ошибка',
          text: 'Широта и долгота должны быть числовыми значениями.',
        });
        return;
      }
  
      // Дополнительная валидация диапазона
      if (latitudeParsed < -90 || latitudeParsed > 90) {
        Swal.fire({
          icon: 'question',
          title: 'Ошибка',
          text: 'Широта должна быть в диапазоне от -90 до 90.',
        });
        return;
      }
      if (longitudeParsed < -180 || longitudeParsed > 180) {
        Swal.fire({
          icon: 'question',
          title: 'Ошибка',
          text: 'Долгота должна быть в диапазоне от -180 до 180.',
        });
        return;
      }
  
      // Функция для форматирования даты
      const formatDate = (date) => {
        const pad = (num) => String(num).padStart(2, '0');
  
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const day = pad(date.getDate());
        const month = pad(date.getMonth() + 1); // Месяцы начинаются с 0
        const year = date.getFullYear();
  
        return `${hours}:${minutes} ${day}.${month}.${year}`;
      };
  
      const currentDate = new Date();
      const formattedDate = formatDate(currentDate);
  
      // Формируем данные для отправки
      const data = {
        latitude: latitudeParsed,
        longitude: longitudeParsed,
        comments: comment || '', // Предполагается, что `comment` определен в вашем компоненте
        date: formattedDate, // Используем отформатированную дату
        danger: dangerLevel || 1, // Предполагается, что `dangerLevel` определен
        createBy: isAnonymous ? 'анонимно' : nickname || 'пользователь', // Предполагается, что `isAnonymous` и `nickname` определены
        icon: selectedCategory || '', // Предполагается, что `selectedCategory` определен
        message: comment || 'мудро', // Или любое другое соответствующее значение
        captchaToken: token,
      };
  
      // Выполняем POST-запрос к API
      const response = await axios.post(`${serverConnect.server}api/add_marker`, data);
  
      // Проверяем статус ответа
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Успех',
          text: 'Маркер успешно добавлен!',
        });
        setShowCaptcha(false);
        onClose(); // Закрываем модальное окно после успешной отправки
        // Дополнительно можно сбросить форму или обновить список маркеров
      } else {
        // Обработка других успешных ответов, если необходимо
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Произошла непредвиденная ошибка. Пожалуйста, попробуйте еще раз.',
        });
      }
    } catch (error) {
      if (error.response) {
        // Сервер ответил с статусом, отличным от 2xx
        if (error.response.status === 409) {
          Swal.fire({
            icon: 'warning',
            title: 'Предупреждение',
            text: 'Метка слишком близка к другой метке. Пожалуйста, выберите другое место.',
          });
        } else {
          // Обработка других статусов ошибок
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            text: 'Произошла ошибка при добавлении маркера. Пожалуйста, попробуйте еще раз.',
          });
        }
      } else if (error.request) {
        // Запрос был сделан, но ответ не получен
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Нет ответа от сервера. Пожалуйста, проверьте ваше интернет-соединение и попробуйте еще раз.',
        });
      } else {
        // Что-то произошло при настройке запроса
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Произошла ошибка при отправке запроса. Пожалуйста, попробуйте еще раз.',
        });
      }
      console.error('Ошибка при отправке данных:', error);
    }
  };

  const handleCaptchaExpire = () => {
    setCaptchaToken(null);
    setShowCaptcha(false);
  };

  const handleCaptchaCancel = () => {
    setShowCaptcha(false);
    if (captchaRef.current) {
      captchaRef.current.resetCaptcha();
    }
  };
  

  const fetchAddress = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_TOKEN}&language=ru`
      );
      if (response.data && response.data.features && response.data.features.length > 0) {
        return response.data.features[0].place_name;
      } else {
        throw new Error('Адрес не найден');
      }
    } catch (error) {
      console.error('Ошибка при получении адреса:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (isOpen && userCoordinates) {
      if (modalMode === 'user') {
        const initializeAddressAndCoordinates = async () => {
          try {
            // Получаем адрес по координатам пользователя
            const fetchedAddress = await fetchAddress(userCoordinates.latitude, userCoordinates.longitude);
            setAddress(fetchedAddress);
  
            // Получаем координаты по адресу
            const coords = await fetchCoordinates(fetchedAddress);
            setLatLng(`${coords.latitude}; ${coords.longitude}`);
            setLatLngError('');
          } catch (error) {
            console.error('Ошибка при инициализации адреса и координат:', error);
            setAddress('Ошибка при получении адреса');
            setLatLng('');
            setLatLngError('Не удалось получить координаты по адресу.');
          }
        };
        initializeAddressAndCoordinates();
      } else if (modalMode === 'context') {
        setLatitude(userCoordinates.latitude.toString());
        setLongitude(userCoordinates.longitude.toString());
      }
    }
  }, [isOpen, userCoordinates, modalMode]);

  const handleLatLngBlur = async () => {
    const latLngInput = latLng.trim();
    const [latStr, lngStr] = latLngInput.split(';').map(s => s.trim());
  
    const latNum = parseFloat(latStr);
    const lngNum = parseFloat(lngStr);
  
    if (
      !isNaN(latNum) &&
      !isNaN(lngNum) &&
      latNum >= -90 &&
      latNum <= 90 &&
      lngNum >= -180 &&
      lngNum <= 180
    ) {
      setLatitude(latStr);
      setLongitude(lngStr);
      setLatitudeError('');
      setLongitudeError('');
      try {
        const fetchedAddress = await fetchAddress(latNum, lngNum);
        setAddress(fetchedAddress);
        setLatLngError('');
      } catch (error) {
        console.error('Ошибка при получении адреса:', error);
        setAddress('Ошибка при получении адреса');
        setLatLngError('Не удалось получить адрес по указанным координатам.');
      }
    } else {
      setLatitudeError('Некорректные значения широты или долготы.');
      setLongitudeError('Некорректные значения широты или долготы.');
      setAddress('');
      setLatLngError('Некорректные координаты.');
    }
  };

  return (
    <div
      className={`create-marker-modal-overlay ${isOpen ? 'visible' : ''}`}
      onClick={onClose}
    >
      <div
        className={`create-marker-modal ${isOpen ? 'visible' : ''}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-header">
          <h2>Добавить метку</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        {modalMode === 'user' ? (
          <div className="user-modal-content">
            {addressError && (
              <div className="error-message above-input">{addressError}</div>
            )}
            <div className="address-input-container">
              <Input
                type="text"
                className="address-input"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  setAddressError('');
                }}
                onBlur={handleAddressBlur}
                placeholder="Введите адрес"
                fluid
                icon={{ name: 'search' }}
                maxLength={300}
              />
              <Input
                type="text"
                className="latlng-input"
                value={latLng}
                onChange={(e) => setLatLng(e.target.value)}
                placeholder="Широта; Долгота"
                onBlur={handleLatLngBlur}
                maxLength={100}
              />
              {latLngError && <div className="error-message">{latLngError}</div>}
            </div>
            <h3>Комментарий</h3>
            <textarea
              className="comment-input"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              onBlur={validateComment}
              placeholder="Расскажи подробнее"
              maxLength={1000}
            />
            {commentError && <div className="error-message">{commentError}</div>}
            <h3>Категория</h3>
            <div className="category-button-group">
              <Button
                className={`category-button ${selectedCategory === 'полицейский' ? 'selected' : ''}`}
                onClick={() => setSelectedCategory('полицейский')}
              >
                <MdLocalPolice className="category-icon" size={24} /> Полиция
              </Button>
              <Button
                className={`category-button ${selectedCategory === 'машина' ? 'selected' : ''}`}
                onClick={() => setSelectedCategory('машина')}
              >
                <PiPoliceCarBold className="category-icon" size={24} /> Машина
              </Button>
            </div>
            <h3>Оцените опасность</h3>
            <ThemeProvider theme={theme}>
              <CustomSlider
                aria-label="Оценка опасности"
                defaultValue={2}
                value={dangerLevel}
                onChange={handleSliderChange}
                color='secondary'
                step={1}
                marks={marks}
                min={1}
                max={5}
                valueLabelDisplay="auto"
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAnonymous}
                      onChange={(e) => setIsAnonymous(e.target.checked)}
                      color="secondary"
                      sx={{
                        color: 'white',
                        '&.Mui-checked': {
                          color: 'secondary.main',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 28,
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          border: 'none',
                        },
                      }}
                    />
                  }
                  label="Оставить метку анонимно"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      color: '#FFFFFF',
                    },
                  }}
                />
              </FormGroup>
            </ThemeProvider>
            <div className="button-container">
              <button
                className="gradient-button-modal"
                onClick={handleSubmit}
              >
                Добавить метку
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className="lat-lng-inputs">
              <div className="input-group">
                <label>Широта</label>
                <Input
                  value={latitude}
                  onChange={(e) => setLatitude(e.target.value)}
                  onBlur={validateLatitude}
                  maxLength={100}
                  placeholder="-90 - 90"
                />
                {latitudeError && <div className="error-message">{latitudeError}</div>}
              </div>
              <div className="input-group">
                <label>Долгота</label>
                <Input
                  value={longitude}
                  onChange={(e) => setLongitude(e.target.value)}
                  onBlur={validateLongitude}
                  maxLength={100}
                  placeholder="-180 - 180"
                />
                {longitudeError && <div className="error-message">{longitudeError}</div>}
              </div>
            </div>
            <h3>Комментарий</h3>
            <textarea
              className="comment-input"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              onBlur={validateComment}
              placeholder="Расскажи подробнее"
              maxLength={1000}
            />
            {commentError && <div className="error-message">{commentError}</div>}
            <h3>Категория</h3>
            <div className="category-button-group">
              <Button
                className={`category-button ${selectedCategory === 'полицейский' ? 'selected' : ''}`}
                onClick={() => setSelectedCategory('полицейский')}
              >
                <MdLocalPolice className="category-icon" size={24} /> Полиция
              </Button>
              <Button
                className={`category-button ${selectedCategory === 'машина' ? 'selected' : ''}`}
                onClick={() => setSelectedCategory('машина')}
              >
                <PiPoliceCarBold className="category-icon" size={24} /> Машина
              </Button>
            </div>
            <h3>Оцените опасность</h3>
            <ThemeProvider theme={theme}>
              <CustomSlider
                aria-label="Оценка опасности"
                defaultValue={2}
                value={dangerLevel}
                onChange={handleSliderChange}
                color='secondary'
                step={1}
                marks={marks}
                min={1}
                max={5}
                valueLabelDisplay="auto"
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAnonymous}
                      onChange={(e) => setIsAnonymous(e.target.checked)}
                      color="secondary"
                      sx={{
                        color: 'white',
                        '&.Mui-checked': {
                          color: 'secondary.main',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 28,
                        },
                        '&.Mui-checked .MuiSvgIcon-root': {
                          border: 'none',
                        },
                      }}
                    />
                  }
                  label="Оставить метку анонимно"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      color: '#FFFFFF',
                    },
                  }}
                />
              </FormGroup>
            </ThemeProvider>
            <div className="button-container">
              <button
                className="gradient-button-modal"
                onClick={handleSubmit}
              >
                Добавить метку
              </button>
            </div>
          </div>
        )}
        {/* Дополнительный контент */}
      </div>

      {/* Captcha Overlay */}
      {showCaptcha && (
        <div className="captcha-overlay">
          <div className="captcha-container">
            <h3>Пожалуйста, пройдите капчу</h3>
            <HCaptcha
              sitekey={HCAPTCHA_SITE_KEY}
              onVerify={handleCaptchaVerify}
              onExpire={handleCaptchaExpire}
              ref={captchaRef}
            />
            <div className="captcha-buttons">
              <button className="gradient-button-modal" onClick={handleCaptchaCancel}>
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateMarkerModal;