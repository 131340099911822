import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import '../Main/Main.css';
import './FooterComponent.css';
import Logo from '../Assets/logo.svg'; // Замените на SVG версию
import { MdKeyboardArrowLeft } from "react-icons/md";
import { serverConnect } from '../Config/Config.js';

const FooterComponent = () => {
  const [links, setLinks] = useState([]);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({});

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await axios.get(`${serverConnect.server}api/banners`);
        if (response.data.success) {
          // Сортировка баннеров по `id`
          const sortedBanners = response.data.banners.sort((a, b) => a.id - b.id);

          // Формируем массив ссылок с приоритетом на web, если нет web, используем tor
          const bannerLinks = sortedBanners.map(banner => ({
            name: banner.name,
            url: banner.web ? banner.web : banner.tor
          }));

          setLinks(bannerLinks);
        }
      } catch (error) {
        console.error('Error fetching banners data', error);
      }
    };

    fetchLinks();
  }, []);

  const handleCopy = (event, text) => {
    navigator.clipboard.writeText(text).then(() => {
      const rect = event.target.getBoundingClientRect();
      setTooltipPosition({ top: rect.top - 30, left: rect.left + rect.width / 2 });
      setTooltipText('Скопировано');
      setShowCopyTooltip(true);
      setTimeout(() => setShowCopyTooltip(false), 2000); // Скрыть сообщение через 2 секунды
    });
  };

  return (
    <footer className="footer" id='footer'>
      <Container>
        <Row className="align-items-center footer-content">
          {/* Лого и название */}
          <Col xs={12} md={4} className="text-center text-md-left mb-4 mb-md-0">
            <div className="logo-container">
              <img src={Logo} alt="Логотип" className="footer-logo" style={{ width: '55px', height: '55px' }}/>
              <span className="company-name">RMaps</span>
            </div>
          </Col>
          
          {/* Динамически созданные ссылки */}
          <Col xs={12} md={8} className="footer-links-container">
            <div className="footer-links text-center">
              {links.map((link, index) => (
                <p key={index}>
                  <a href={link.url} className="footer-link" target="_blank" rel="noopener noreferrer">
                    {link.name}
                  </a>
                </p>
              ))}
            </div>
            <div className="footer-links text-center">
              <p><a href="/#marketplace" className="footer-link">Маркетплейсы</a></p>
              <p><a href="/#forum" className="footer-link">Форумы</a></p>
              <p><a href="/#exchanger" className="footer-link">Обменники</a></p>
              <p><a href="/#telegram" className="footer-link">Telegram каналы</a></p>
              <p><a href="/#vpn" className="footer-link">VPN сервисы</a></p>
              <p><a href="/#filechange" className="footer-link">Файлообменники</a></p>
            </div>
            <div className="footer-links text-center">
              <p className='footer-linkk' style={{fontWeight: 'bold'}}>Контакты:</p>
              {showCopyTooltip && (
                <div className="copy-tooltip" style={{ top: tooltipPosition.top, left: tooltipPosition.left }}>
                  {tooltipText}
                </div>
              )}
              <p style={{marginTop: '10px'}}><a href="#link3" className="footer-link" onClick={(e) => handleCopy(e, 'element-link')}>Element <MdKeyboardArrowLeft style={{fontWeight: 'bold', color: '#c3073fcc', fontSize: '22px'}}/></a></p>
              <p><a href="#link1" className="footer-link" onClick={(e) => handleCopy(e, 'telegram-link')}>Telegram <MdKeyboardArrowLeft style={{fontWeight: 'bold', color: '#c3073fcc', fontSize: '22px'}}/></a></p>
              <p><a href="#link2" className="footer-link" onClick={(e) => handleCopy(e, 'jabber-link')}>Jabber <MdKeyboardArrowLeft style={{fontWeight: 'bold', color: '#c3073fcc', fontSize: '22px'}}/></a></p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default FooterComponent;
