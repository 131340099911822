// PublicRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { decryptData } from '../Crypt/Crypt.js';

const PublicRoute = () => {
    const token = localStorage.getItem('authToken');
    const isAuthenticated = token ? decryptData(token) : null;

    return isAuthenticated ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoute;
