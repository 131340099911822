import React, { useState, useEffect, useRef } from 'react';
import './Reg.css';
import { Input, Button } from 'semantic-ui-react';
import { FaWarehouse } from "react-icons/fa6";
import { serverConnect } from "../Config/Config.js"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const Reg = () => {
    const [formData, setFormData] = useState({
        username: '',
        nickname: '',
        password: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const navigate = useNavigate();
    const hcaptchaRef = useRef(null);

    const validateField = (name, value) => {
        let error = '';
        // Пример простой валидации (можете заменить на свою логику)
        if (!value) {
            error = 'Это поле обязательно';
        } else if (name === 'username' && value.length < 5) {
            error = 'Логин должен быть не менее 5 символов';
        } else if (name === 'nickname' && value.length < 4) {
            error = 'Никнейм должен быть не менее 4 символов';
        } else if (name === 'password' && value.length < 7) {
            error = 'Пароль должен быть не менее 7 символов';
        } else if (name === 'password' && !/[A-Z]/.test(value)) {
            error = 'Пароль должен содержать хотя бы одну заглавную букву';
        } else if (name === 'password' && !/[0-9]/.test(value)) {
            error = 'Пароль должен содержать хотя бы одну цифру';
        } else if (name === 'confirmPassword' && value !== formData.password) {
            error = 'Пароли не совпадают';
        }

        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    useEffect(() => {
        const isValid = !Object.values(errors).some(error => error) &&
            formData.username &&
            formData.nickname &&
            formData.password &&
            formData.confirmPassword &&
            formData.password === formData.confirmPassword &&
            captchaToken;

        setIsFormValid(isValid);
    }, [formData, errors, captchaToken]);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        validateField(name, value);
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);
    };

    const handleCaptchaVerify = (token) => {
        setCaptchaToken(token);
    };

    const handleSubmit = async () => {
        if (isFormValid) {
            try {
                const response = await fetch(`${serverConnect.server}api/registration`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ ...formData, 'h-captcha-response': captchaToken })
                });
                const result = await response.json();
                if (result.message === "Successful registration") {
                    toast.success("Регистрация успешна!", {
                        onClose: () => navigate('/login'),
                        autoClose: 1000
                    });
                } else {
                    toast.error(result.message || "Ошибка регистрации");
                    hcaptchaRef.current.resetCaptcha(); // Сбрасываем капчу
                }
            } catch (error) {
                toast.error("Произошла ошибка при регистрации");
                hcaptchaRef.current.resetCaptcha(); // Сбрасываем капчу
            }
        }
    };

    return (
        <div className="main-reg">
            <ToastContainer />
            <div className="container-reg">
                <div className="title-container">
                    <a href="/" className="title-link">
                        <h1 className="registration-title">
                            <FaWarehouse color="#C3073F" size={33} style={{ marginRight: '10px' }} /> RedMap
                        </h1>
                    </a>
                </div>
                <div className="input-container">
                    <label className="input-label">Логин</label>
                    <Input
                        icon='user'
                        iconPosition='left'
                        placeholder='Введите логин...'
                        className="custom-input"
                        name="username"
                        value={formData.username}
                        onInput={handleInput}
                        onBlur={handleBlur}
                        maxLength="50"
                    />
                    {errors.username && <span className="error-text">{errors.username}</span>}
                </div>
                <div className="input-container">
                    <label className="input-label">Никнейм</label>
                    <Input
                        icon='paper plane'
                        iconPosition='left'
                        placeholder='Введите никнейм...'
                        className="custom-input"
                        name="nickname"
                        value={formData.nickname}
                        onInput={handleInput}
                        onBlur={handleBlur}
                        maxLength="50"
                    />
                    {errors.nickname && <span className="error-text">{errors.nickname}</span>}
                </div>
                <div className="input-container">
                    <label className="input-label">Придумайте пароль</label>
                    <Input
                        icon='lock'
                        iconPosition='left'
                        placeholder='Введите пароль...'
                        className="custom-input"
                        type="password"
                        name="password"
                        value={formData.password}
                        onInput={handleInput}
                        onBlur={handleBlur}
                        maxLength="50"
                    />
                    {errors.password && <span className="error-text">{errors.password}</span>}
                </div>
                <div className="input-container">
                    <label className="input-label">Подтвердите пароль</label>
                    <Input
                        icon='lock'
                        iconPosition='left'
                        placeholder='Подтвердите пароль...'
                        className="custom-input"
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onInput={handleInput}
                        onBlur={handleBlur}
                        maxLength="50"
                    />
                    {errors.confirmPassword && <span className="error-text">{errors.confirmPassword}</span>}
                </div>
                <div className="hcaptcha-container">
                    <HCaptcha
                        sitekey="dd123fb4-e0b8-4636-a408-3734ed7ae054"
                        onVerify={handleCaptchaVerify}
                        ref={hcaptchaRef}
                    />
                </div>
                <Button
                    className="submit-button"
                    disabled={!isFormValid}
                    onClick={handleSubmit}
                >
                    Зарегистрироваться
                </Button>
                <div className="login-link">
                    <span>Уже есть аккаунт?</span>
                    <a href="/login" className="login-link-text">Войти</a>
                </div>
            </div>
        </div>
    );
};

export default Reg;
